.page-404 {
  .pb25 {
    padding-bottom: 25px;
  }
  .cen {
    text-align: center !important;
  }
  .title_notFound  {
    background: #24520c;
    color: #efd776;
    padding: 8px;
    @include font-size(16);
    font-weight: bold;
  }
  .main-text {
    padding: 10px 10px 40px 10px;
    margin: 0 0 20px 0;
    background: #fff;
    border: 1px solid #ccc;
    .notFound-bg {
      float: right;
      margin: 10px 10px 10px 30px;
      @media screen and (max-width: 640px) {
        float: none;
        text-align: center;
      }
    }
    p {
      text-align: justify;
      line-height: 1.5;
      @include font-size(14);
      color: #000;
      a {
        text-decoration: underline;
        color: #000;
      }
      a:hover, a:active {
        color: #bc0d17;
      }
    }
  }
}