#lNav {
  margin: 0 0 15px 0;
}

#mainTitle {
  background: url(/images/common/main_tit_bg.jpg) 0 0 no-repeat;
  color: #d4be64;
  background-size: 100% 100%;
  font-weight: bold!important;
  letter-spacing: 0.1em;
  overflow: hidden;
  text-align: center;
  padding: 20px 0;
  @include font-size(18);
  &.mainTitle02 {
    @include font-size(20);
    @media screen and (max-width: 768px){
      @include font-size(18);
    }
  }
}

.pageTop {
  margin: 15px 0 10px;
  text-align: right;
  &-right {
    a {
      display: inline-block;
    }
  }
}

.sidebar-com {
  .lNav {
    position: relative;
  }
  .lNavbottom {
    position: absolute;
    @media screen and (max-width: 768px){
      display: none;
    }
  }
  .w100 {
    width: 100%;
  }
}

.page-caution {
  @include font-size(14);
  .fs15 {
    font-size: 15px !important;
    font-size: 1.5rem !important;
  }
  .mb10 {
    margin-bottom: 10px;
  }
  .mb15 {
    margin-bottom: 15px;
  }
  #userTit {
    &.ttl_caution {
      margin: 0 0 20px 0;
      display: inline-block;
      width: 100%;
      img {
        width: 100%;
      }
    }
  }
  .main-user {
    padding: 20px 30px;
    border: 1px solid #d1d1d1;
    background: #fff;
    border-radius: 5px;
    line-height: 1.5;
    @media screen and (max-width:768px) {
      padding: 15px 15px;
    }
    p {
      @include font-size(13);
      text-align: justify;
    }
    .userList {
      display: inline-block;
      width: 100%;
      li {
        list-style-type: disc;
        list-style-position: outside;
        margin: 0 0 0 20px;
        @include font-size(13);
      }
    }
    .userCautions_B {
      background: #FFF;
      font-size: 100%;
      font-weight: bold;
      margin: 1em;
      padding: 1em;
      .linkRed {
        color: #f5616a;
        text-decoration: underline;
        &:visited {
          color: #f5616a;
        }
        &:hover {
          color: #0087ba;
        }
      }
    }
  }
}