.col-2-content-wrapper {
	display: flex;
	justify-content: space-between;
	.main-content {
		width: calc(100% - 270px);
	}
	.sidebar {
		width: 245px;
		margin-bottom: 30px;
	}
	@media screen and (max-width: 768px) {
		display: block;
		.main-content, .sidebar {
			width: 100%;
			margin-bottom: 30px;
		}
	}
}

.breadcrumb {
	margin: 15px 0;
	padding-left: 25px;
	position: relative;
	&:before {
		display: block;
		content: '';
		background: url("../images/common/icon_waka.gif") no-repeat center center;
		background-size: 100% 100%;
		width: 23px;
		height: 23px;
		position: absolute;
		top: 0;
		left: 0;
	}
	* {
		@include font-size(14);
	}
	a {
		text-decoration: underline;
		&:visited {
			color: #bc0d17;
		}
		&:link {
			color: #f5616a;
		}
	}
	i {
		margin: 0 2px;
	}
}

#lNav {
	background: url("../images/common/lnav_bg.png") no-repeat left top;
	background-size: 100% 100%;
	padding-bottom: 10px;
	@media screen and (max-width: 768px) {
		background: #36412e;
	}
	> ul {
		margin: 0 10px;
		> li {
			background-color: #fff;
			font-weight: bold;
			position: relative;
			> a {
				display: block;
				color: #000;
				@include font-size(13);
				padding: 12px 5px 11px 25px;
				position: relative;
				border-bottom: 1px solid #36412e;
				&:before {
					display: block;
					content: "";
					width: 7px;
					height: calc(100% - 2px);
					position: absolute;
					left: 1px;
					top: 1px;
					background-color: #1b3a01;
				}
				&:hover {
					background-color: #c6b25e;
					&:before {
						background-color: #855d26;
					}
				}
			}
			ul {
				li {
					a {
						color: #333;
						display: block;
						text-decoration: underline;
						padding: 11px 15px 11px 26px;
						border-bottom: 1px solid #36412e;
						position: relative;
						&:before {
							content: "\f0da";
							font-family: FontAwesome, sans-serif;
							position: absolute;
							left: 20px;
							@include v-align;
						}
						&:hover {
							background-color: #c6b25e;
							&:before {
								color: white;
							}
						}
					}
				}
			}
		}
	}
	ul {
		li {
			&.cur {
				a {
					background: #eacf6c;
					&:before{
						color: #fff;
					}
				}
			}
		}
	}
}

#lNavTitle {
	background: url("../images/common/lnav_tit_bg.png") no-repeat left top;
	background-size: 100% auto;
	@media screen and (max-width: 768px) {
		background: #36412e;
	}
	h2 {
		color: #d4be64;
		min-height: 24px;
		padding: 17px 0 11px 55px;
		@include font-size(16);
		font-weight: bold;
		font-family: $font-mincho;
		@media screen and (max-width: 768px) {
			padding-left: 20px;
		}
		span {
			font-size: smaller;
		}
	}
}

.lNav-red {
	ul {
		border-bottom: 2px solid #bc0d17;
	}
}

.unit_thumbnail {
	padding: 0 !important;
	margin: 0 !important;
	display: inline-block;
	content: '';
	z-index: 9998;
	position: absolute;
	top: 0;
	left: 0;
}

#pr {
	h2 {
		margin: 0 0 10px 0;
	}
	h3 {
		margin: 10px 0 10px 0;
	}
	ul {
		li {
			float: left;
			padding: 0 2px 5px 2px;
		}
	}
}

#mainTitle2 {
	background: url("../images/forum/main_tit_bg.jpg") no-repeat center top;
	background-size: 100% 100%;
	color: #fff;
	letter-spacing: 0.2em;
	overflow: hidden;
	padding: 18px;
	text-align: center;
	position: relative;
	h2 {
		line-height: 1.2;
		@include font-size(20);
		color: #d4be64;
		font-weight: bold;
		font-family: $font-mincho;
		span {
			@include font-size(17);
			@media screen and (max-width: 768px) {
				@include font-size(14);
				padding: 0 10px;
				display: block;
			}
		}
	}
}

#mainInner {
	background: #efedd7;
	border-width: 0 2px 2px 2px;
	border-color: #8e9184;
	border-style: solid;
	margin-bottom: 30px;
	padding: 19px;
}

.img-block {
	width: 100%;
}

#target {
	margin-bottom: 20px;
	@include font-size(14);
	dl {
		margin: 20px 20px 5px;
		border-bottom: 1px dotted #999;
		@media screen and (max-width: 768px) {
			margin: 20px 0 10px;
		}
		dt {
			float: left;
			width: 100px;
			padding: 10px 0 10px 10px;
			clear: both;
			font-weight: bold;
			border-top: 1px dotted #999;
			@media screen and (max-width: 768px) {
				float: none;
				width: auto;
				padding-left: 0;
				padding-bottom: 0;
			}
		}
		dd {
			width: calc(100% - 100px);
			margin-left: 100px;
			padding: 10px 10px 10px 0;
			border-top: 1px dotted #999;
			@media screen and (max-width: 768px) {
				border-top: none;
				float: none;
				width: auto;
				margin-left: 0;
			}
		}
	}
}

#scene {
	margin: 0 auto 30px;
	text-align: center;
}

.call_number_area {
	margin: 0 auto;
	max-width: 536px;
	background-color: #fdf9ef;
	border: 3px #a4825f solid;
	font-weight: bold;
	@include font-size(18);
	text-align: center;
	border-radius: 5px;
	.ttl_call_number{
		padding: 5px;
	}
	.tel_call_number {
		display: block;
		line-height: 1.2;
		a{
			color: red;
		}
		@media screen and (min-width: 769px) {
			pointer-events: none;
		}
		@media screen and (max-width: 340px) {
			font-size: 26px !important;
		}
		span {
			line-height: 1.2 !important;
		}
	}
	.ttl_call_number_houjin {
		font-size: 18px !important;
		span {
			font-size: 16px;
		}
	}
	.ttl_number{
		padding: 0 5px;
		@media screen and (max-width: 768px) {
			@include font-size(14);
		}
	}
	.ttl_call_houjin{
		@include font-size(18);
		padding: 5px 10px;
		@media screen and (max-width: 768px) {
			@include font-size(14);
			padding: 5px;
		}
		span{
			display: block;
			@include font-size(16);
			font-weight: normal;
			@media screen and (max-width: 768px) {
				@include font-size(13);
			}
		}
	}
}


.imgWrap{
	.back-to-top{
		img{
			margin: 15px 0 10px;
		}
	}
}


#bannerArea {
	ul {
		display: flex;
		justify-content: space-around;
		align-items: center;
		@media screen and (max-width: 768px) {
			flex-wrap: wrap;
			li {
				margin-bottom: 20px;
			}
		}
	}
}

.center {
	text-align: center !important;
}

#gojuonList {
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 30px;
	li {
		padding: 5px;
		margin-right: 5px;
		border: 1px solid #cccccc;
		border-radius: 5px;
		background: #ffffff;
		background: -moz-linear-gradient(top, #ffffff 0%, #f1f1f1 100%);
		background: -webkit-linear-gradient(top, #ffffff 0%, #f1f1f1 100%);
		background: linear-gradient(to bottom, #ffffff 0%, #f1f1f1 100%);
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#f1f1f1', GradientType=0);
		@include smooth-transition;
		a {
			display: block;
			padding: 2px 8px;
			border: 1px solid #cccccc;
			font-weight: bolder;
			background-color: #fff;
			color: #000;
			border-radius: 5px;
			overflow: hidden;
			text-shadow: 0 0 10px rgba(0, 0, 0, .1), 0 0 10px rgba(0, 0, 0, .1), 0 0 10px rgba(0, 0, 0, .1), 0 0 10px rgba(0, 0, 0, .1);
			@include font-size(16);
			@include smooth-transition;
		}
		&:hover {
			position: relative;
			@include smooth-transition;
			&:after {
				display: block;
				content: '';
				width: 0;
				height: 0;
				border: 5px solid transparent;
				border-top: 5px solid #0f88bc;
				position: absolute;
				bottom: -4px;
				@include h-align;
				z-index: 1;
			}
			a {
				color: white;
				background: url("../images/common/blue-pattern.png") no-repeat center center;
				background-size: 100% 100%;
				@include smooth-transition;
				text-shadow: 0 0 10px rgba(255, 255, 255, .1), 0 0 10px rgba(255, 255, 255, .1), 0 0 10px rgba(255, 255, 255, .1), 0 0 10px rgba(255, 255, 255, .1);
			}
		}
	}
}

.forum_shityou_bg {
	padding: 20px;
	background: url("../images/forum/forum_shityou_bg.gif") repeat-y left top;
	background-size: 100% auto;
	p {
		@include font-size(14);
	}
}

.forum_merumaga_bg {
	padding: 20px;
	background: url("../images/forum/forum_merumaga_bg.gif") repeat-y left top;
	background-size: 100% auto;
}

.backBox {
	border: #366a78 1px solid;
	padding-bottom: 15px;
	.title {
		background: #366a78;
		color: #FFF;
		font-weight: bold;
		padding: 5px;
		@include font-size(14);
	}
	a {
		background: url("../images/common/arrow_grey.gif") left center no-repeat;
		display: block;
		margin: 10px;
		padding-left: 10px;
		color: #000;
		float: left;
		text-decoration: underline;
		@include font-size(14);
		&:hover {
			color: #bc0d17;
		}
	}
}

.row {
	overflow: hidden;
	.textL {
		float: left;
		width: 60%;
		@include font-size(14);
		@media screen and (max-width: 768px) {
			width: 100%;
			margin-bottom: 15px;
		}
	}
	.textR {
		float: right;
		width: 35%;
		@media screen and (max-width: 768px) {
			width: 100%;
		}
		a {
			display: block;
			width: 100%;
			text-align: center;
			margin-bottom: 10px;
		}
	}
}

.forum_custom_bg {
	padding: 20px;
	background: url("../images/forum/forum_custom_bg.gif") repeat-y left top;
	background-size: 100% auto;
}

.forum_shityou_top,
.forum_merumaga_top,
.forum_custom_top {
	margin-top: 20px;
	img {
		vertical-align: bottom;
	}
}

.forum_shityou_bottom,
.forum_merumaga_bottom,
.forum_custom_bottom {
	img {
		vertical-align: top;
	}
}

.com-anchor {
	padding-top: 108px;
	margin-top: -108px;
	@media screen and (max-width: 768px) {
			padding-top: 64px;
			margin-top: -64px;
	}
}