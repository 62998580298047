.jimakuTit {
	background: url(../images/jimaku/bg_gradation_gray.jpg) 0 0 repeat-x;
	border: 1px solid #ccc;
	padding: 0.5em;
	padding-left: 0.8em;
	color: #333;
	font-weight: bold;
	@include font-size(18);
}

.mainTitle {
	background: url(../images/jimaku/main_tit_bg.gif) 0 0 repeat-x;
	color: #fff;
	@include font-size(19);
	font-weight: bold !important;
	letter-spacing: 0.2em;
	overflow: hidden;
	padding: 9px 0;
	text-align: center;
}

.page-jimaku {
	border-width: 0 1px 1px 1px;
	border-color: #ccc;
	border-style: solid;
	margin-bottom: 30px;
	padding: 19px;
	@include font-size(16);
	background: white;
	@media screen and (max-width: 768px) {
		@include font-size(14);
		padding: 10px;
	}
	& + p.txt-center {
		margin-bottom: 50px;
	}
}

.title-item {
	border-left: 5px solid #4e690d;
	border-bottom: 1px solid #a2b27c;
	margin: 20px 0 25px;
	padding: 0.3em;
	padding-left: 0.5em;
	color: #333;
	background: #d9d4a3;
	@include font-size(16);
	font-weight: bold;
}

.scrol-item {
	padding: 10px 0;
	a {
		display: inline-block;
		color: #0087ba;
		position: relative;
		text-decoration: underline;
		@media screen and (max-width: 350px) {
			@include font-size(12);
		}
		&:hover {
			color: #977c10;
		}
	}
}

.jimakuCap {
	@include font-size(14);
	.right-img {
		float: right;
		margin-left: 10px;
		min-width: 45%;
		max-width: 50%;
		@media screen and (max-width: 768px) {
			float: none;
			margin: 0 auto 15px;
			min-width: auto;
			max-width: 100%;
			display: block;
		}
	}
	p {
		margin-bottom: 10px;
		&:last-child {
			margin-bottom: 0;
		}
		small{
			@include font-size(10);
		}
	}
}

.jimakuCap-img {
	p {
		img {
			margin-bottom: 15px;
		}
	}
	.pageTop {
		margin: 5px 0 30px;
	}
}

.accordion02 {
	& + .pageTop {
		margin: 5px 0 10px;
	}
}


.accordion02 {
	dl {
		margin-bottom: 10px;
		border: 1px solid #ccc;
		@include font-size(14);
		background: #fffef1;
		&:last-child {
			margin-bottom: 0;
		}
		dt, dd {
			padding: 1em;
			padding-left: 50px;
			&:before {
				content: "";
				width: 28px;
				height: 28px;
				border-radius: 4px;
				position: absolute;
				left: 13px;
			}
		}
		dt {
			position: relative;
			font-weight: bold;
			border-bottom: 1px dotted #ccc;
			&:before {
				background: url(../images/jimaku/icon_faq_q.gif) no-repeat;
				@include v-align;
			}
		}
		dd {
			position: relative;
			&:before {
				background: url(../images/jimaku/icon_faq_a.gif) no-repeat;
			}
		}
	}
}

.sidebar-list {
	img {
		width: 100%;
		@media screen and (max-width: 768px) {

		}
	}
	a {
		&:hover {
			opacity: 0.7;
			@include smooth-transition;
		}
	}
	.mt20 {
		margin-top: 20px;
	}
	ul {
		li {
			a {
				img {
					width: auto;
				}
			}
		}
	}
	@media screen and (max-width: 768px) {
		a {
			display: block;
			width: 48%;
			&.left-img {
				float: left;
			}
			&.right-img {
				float: right;
			}
			img {
				margin-bottom: 15px;
			}
		}
		ul {
			li {
				float: left;
				width: 48%;
				&:nth-child(2n) {
					float: right;
				}
				a {
					width: 100%;
					img {
						width: 100%;
					}
				}
			}
		}
	}
}

@media screen and (max-width: 768px) {
	.col-2-content-wrapper {
		.mb20 {
			margin-bottom: 10px;
			a {
				width: 48%;
				&.left-img {
					float: left;
				}
				&.right-img {
					float: right;
				}
				img {
					margin-bottom: 0;
				}
			}
		}
		.pr-sp {
			ul {
				li {
					float: left;
					width: 48%;
					&:nth-child(2n) {
						float: right;
					}
					img {
						width: 100%;
					}
				}
			}
		}
	}
}

.mt30 {
	margin-top: 30px;
}
#bannerArea {
	@media screen and (max-width: 1200px) {
		@media screen and (min-width: 0 \0
		) {
			.imgWrap {
				img {
					width: 100%;
				}
			}
		}
	}
}