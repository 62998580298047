.osusume-wrap {
	padding: 30px 0 0;
	.top-ttl {
		margin-bottom: 35px;
		@include font-size(34);
		@media screen and (max-width: 768px) {
			letter-spacing: 0;
			@include font-size(30);
		}
	}
	.plan-list {
		&.sp-list {
			.plan-box-02 {
				&:nth-of-type(n+5) {
					@media screen and (max-width: 768px) {
						display: none;
					}
				}
			}
		}
	}
	.append {
		@media screen and (max-width: 768px) {
			color: #977c10;
			text-align: center;
			font-weight: 500;
			position: relative;
			@include font-size(16);
			&:after {
				display: inline-block;
				content: '';
				width: 6px;
				height: 6px;
				border-bottom: 2px solid #977c10;
				border-right: 2px solid #977c10;
				position: absolute;
				top: 100%;
				left: 50%;
				transform: translateX(-50%) rotate(45deg);
			}
		}
	}
}

.box-navigation {
	background-color: #f1f1f1;
	padding: 8px 0;
	margin-bottom: -48px;
	@media screen and (max-width: 768px) {
		display: none;
	}
	a {
		display: inline-block;
		color: #977c10;
		font-weight: bold;
		@include font-size(20);
		position: relative;
		padding-left: 25px;
		margin-right: 40px;
		@media screen and (max-width: 768px) {
			@include font-size(16);
		}
		&:before {
			display: block;
			content: '';
			width: 6px;
			height: 6px;
			border-bottom: 2px solid #977c10;
			border-right: 2px solid #977c10;
			position: absolute;
			top: 35%;
			left: 0;
			transform: rotate(45deg) translateY(-50%);
		}
	}
	&.is-fixed {
		position: fixed;
		left: 0;
		z-index: 99;
		width: 100%;
	}
}

.box-content {
	margin-top: 40px;
	@media screen and (max-width: 768px) {
		margin-top: 20px;
	}
	&:first-of-type {
		margin-top: 88px;
		@media screen and (max-width: 768px) {
			margin-top: 0;
		}
	}
	&:last-of-type {
		@media screen and (max-width: 768px) {
			.append {
				margin-bottom: 40px;
			}
		}
	}
}