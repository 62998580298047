.page-kojinjoho {
  background: url(/images/common/page_bg.jpg) repeat;
  @include font-size(14);
  .mb10 {
    margin-bottom: 10px;
  }
  .mb20 {
    margin-bottom: 20px;
  }
  .mb30 {
    margin-bottom: 30px;
  }
  .mainTitle {
    background: url(/images/common/main_tit_bg.jpg) 0 0 no-repeat;
    background-size: 100% 100%;
    color: #d4be64;
    font-family: "HGP 創英プレゼンス EB","HGP 明朝 E","ヒラギノ明朝 ProN W6","HiraMinProN-W6","ＭＳ Ｐ明朝","MS PMincho",serif;
    font-size: 153.9%;
    font-weight: bold!important;
    height: 60px;
    letter-spacing: 0.1em;
    overflow: hidden;
    padding: 10px 0 0 0;
    text-align: center;
  }
  &.page-kojinjoho-useroubo {
    .useroubo-main {
      padding: 20px 0;
    }
    .useroubo-content {
      background: #fff;
      padding: 20px 40px 0 40px;
      line-height: 1.5;
      @media screen and (max-width: 768px) {
        padding: 10px 20px 0 20px;
      }
      ol {
        margin: 0 2em 2em 4em;
        @media screen and (max-width: 768px) {
          margin: 0 1em 1em 2em;
        }
        position: relative;
        li {
          list-style: none;
          padding-left: 18px;
          &:before {
            position: absolute;
            content: counter(number) ". ";
            counter-increment: number;
            left: 0;
          }
        }
      }
      form {
        text-align: center;
        padding-bottom: 20px;
      }
      .mail-link {
        text-decoration: underline;
        color: #000;
        &:hover {
          color: #bc0d17;
        }
      }
      .mail-icn {
        @include font-size(10);
      }
      .btn-sty {
        width: 100px;
        height: 28px;
        font-weight: bold;
      }
    }    
  }
}