#howto_index {
	.howto-wrap{
		padding: 30px 0 0;
	}
	.top-ttl.flex-ttl{
		padding-left: 0;
		&::before{
			display: none;
		}
	}
	.table-howto{
		overflow-x: auto;
    	-webkit-overflow-scrolling: touch;
		margin-bottom: 30px;
		table{
			width: 100%;
			@media screen and (max-width: 768px){
				width: 767px;
			}
			th,td{
				border: 1px solid #aaaaaa;
			}
			th{
				color: #FFF;
				text-align: center;
				background-color: #333333;
				@include font-size(14);
				padding: 5px 0;
				&:nth-of-type(1){
					width: 120px;
				}
				&:nth-of-type(2){
					width: 125px;
				}
				&:nth-of-type(3){
					width: 200px;
				}
			}
			td{
				@include font-size(16);
				vertical-align: top;
				padding: 9px 15px;
				span{
					@include font-size(14);
				}
			}
		}
		&.table-howto-fix{
			table{
				th{
					padding: 1px 0;
					border: 1px solid #333;
				}
			}
		}
	}
}