.call_number_area {
	max-width: 536px;
	background-color: #fdf9ef;
	border: 3px #a4825f solid;
	font-weight: bold;
	font-size: 18px;
	font-size: 1.8rem;
	border-radius: 5px;
	text-align: center;
	.tel_call_number {
		padding: 0 0 15px;
		font-size: 30px;
		font-size: 3rem;
		color: red;
		span {
			line-height: 5.5px;
			font-size: 14px;
			font-size: 1.4rem;
			color: #333;
			display: block;
		}
	}
}
.ttl_call_number {
	margin-bottom: 10px;
	padding: 5px 0;
	background-color: #84603a;
	font-size: 15px;
	font-size: 1.5rem;
	color: #fff;
	border-radius: 2px 2px 0 0;
}
ol {
	list-style: none;
}
.page-inquiry {
	@include font-size(15);
	.accordion02 {
		dl {
			dt, dd {
				a {
					line-height: 1.5;
					color: #000;
					text-decoration: underline;
					@include font-size(13);
					&:hover {
						color: #a70000;
					}
				}
			}
		}
	}
	&.index {
		@include font-size(14);
		#mainInner {
			background: #fff;
			border-width: 0 1px 1px 1px;
			border-color: #ccc;
			border-style: solid;
			margin-bottom: 30px;
			padding: 19px;
    }
    #mainTitle {
      min-height: 65px;
    }
		.inquiryTit {
			background: url(/images/common/bg_gradation_gray.jpg) 0 0 repeat-x;
			border-top: 1px solid #ccc;
			border-left: 1px solid #ccc;
			border-right: 1px solid #ccc;
			padding: 1em 1em 1em 0.8em;	
			h2 {
				@include font-size(15);
				font-weight: bold;
			}
			span {
				background: url(/images/common/arrow_red.gif) 0 4px no-repeat;
				padding-left: 15px;
			}
		}
		.inquiryList {
			border: 1px solid #ccc;
			margin-bottom: 1em;
			padding: 1.2em 1.2em 0 1.5em;
			li {
				background: url(/images/common/arrow_red.gif) 0 4px no-repeat;
				padding: 0 0 1.2em 15px;
				a {
					text-decoration: underline;
					color: #bc0d17;
					&:hover {
						color: #0087ba;
					}
				}
			}
		}
	}
	.sidebar {
		#lNav {
			#lNavTitle{
				h2 {
					&.style01 {
						letter-spacing: -2px;
						line-height: 17px;
						margin-bottom: 10px;
					}
				}
			}
		}
	}
}
.page-inquiry-faq {
	.main-content {
		padding-bottom: 50px;
		@media screen and (max-width: 768px){
			padding-bottom: 30px;
		}
	}
	.accordion02 {
		dl {
			dt, dd {
				background: #ffffff;
			}
		}
	}
}