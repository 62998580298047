.cable {
	padding-top: 47px;
	margin-bottom: 28px;
	@media screen and (max-width: 768px) {
		padding-top: 15px;
		margin-bottom: 15px;
	}
	img {
		width: 100%;
	}
}

.detailtext {
	font-size: 19px;
	line-height: 1.3 !important;
	text-align: center;
	@media screen and (max-width: 768px) {
		font-size: 15px;
		text-align: left;
		line-height: 1.4 !important;
		margin-bottom: 15px;
	}
}

.japanmapwrapper {
	display: flex;
	justify-content: center;
	margin-bottom: 55px;
	.japanmap {
		width: 547px;
		display: block;
		@media screen and (max-width: 768px) {
			display: none;
		}
	}
	.area {
		width: 300px;
		margin-left: 30px;
		padding-top: 120px;
		li {
			margin-bottom: 5px;
		}
		@media screen and (max-width: 768px) {
			margin-left: 0;
			width: 100%;
			padding-top: 0;
			&.sp {
				display: flex;
				flex-wrap: wrap;
				justify-content: space-around;
				li {
					width: 45%;
					margin-bottom: 4px;
				}
			}
		}
	}
}

.right {
	text-align: right;
}

#shichowrap {
	padding-bottom: 50px;
	@include font-size(14);
}

.areawrap {
	margin-bottom: 45px;
	p {
		&.ttl {
			width: 70px;
			background: #3485c7;
			color: #fff;
			@include font-size(15);
			font-weight: bold;
			border-right: none;
			padding: 5px 0;
			text-align: center;
			@media screen and (max-width: 768px) {
				padding: 4px 0;
				@include font-size(13);
			}
			&.ttl2 {
				background: #83cafc;
			}
			&.ttl3 {
				background: #26a326;
			}
			&.ttl4 {
				background: #b6c7b0;
			}
			&.ttl5 {
				background: #be74e5;
			}
			&.ttl6 {
				background: #009696;
			}
			&.ttl7 {
				background: #b3e49c;
			}
			&.ttl8 {
				background: #f9cb86;
			}
			&.ttl9 {
				background: #f39500;
			}
			&.ttl10 {
				background: #ea3e16;
			}
		}
	}
	ul {
		width: 100%;
		border-bottom: 2px solid #3485c7;
		margin-bottom: 11px;
		@media screen and (max-width: 768px) {
			padding-left: 155px;
			position: relative;
		}
		&.bb2 {
			border-bottom-color: #83cafc;
		}
		&.bb3 {
			border-bottom-color: #26a326;
		}
		&.bb4 {
			border-bottom-color: #b6c7b0;
		}
		&.bb5 {
			border-bottom-color: #be74e5;
		}
		&.bb6 {
			border-bottom-color: #009696;
		}
		&.bb7 {
			border-bottom-color: #b3e49c;
		}
		&.bb8 {
			border-bottom-color: #f9cb86;
		}
		&.bb9 {
			border-bottom-color: #f39500;
		}
		&.bb10 {
			border-bottom-color: #ea3e16;
		}
		li {
			width: 100px;
			float: left;
			font-size: 18px;
			padding: 12px 0 10px 10px;
			line-height: 1;
			@media screen and (max-width: 768px) {
				width: 80px;
				padding: 6px 0 7px 20px;
				@include font-size(14);
			}
			&.prefecture {
				width: 153px;
				color: #fff;
				@include font-size(20);
				font-weight: bold;
				background: #3485c7;
				text-align: center;
				@media screen and (max-width: 768px) {
					padding: 6px 0 5px;
					@include font-size(14);
					position: absolute;
					top: 2px;
					left: 0;
				}
				&.prefecture2 {
					background: #83cafc;
				}
				&.prefecture3 {
					background: #26a326;
				}
				&.prefecture4 {
					background: #b6c7b0;
				}
				&.prefecture5 {
					background: #be74e5;
				}
				&.prefecture6 {
					background: #009696;
				}
				&.prefecture7 {
					background: #b3e49c;
				}
				&.prefecture8 {
					background: #f9cb86;
				}
				&.prefecture9 {
					background: #f39500;
				}
				&.prefecture10 {
					background: #ea3e16;
				}
			}
			a {
				background: url("../images/shicho/cable/cableicon.png") no-repeat left center;
				padding-left: 16px;
				color: #000;
				@media screen and (max-width: 768px) {
					padding-left: 7px;
					background-size: 4px 7px;
					@include font-size(10);
				}
				&:hover {
					color: #a70000;
				}
			}
		}
		&.item {
			border-bottom: none;
			margin-bottom: 6px;
			display: flex;
			justify-content: space-between;
			@media screen and (max-width: 768px) {
				display: none;
			}
			li {
				border-right: 1px solid #3f3f3f;
				box-sizing: border-box;
				background: url("../images/shicho/cable/areattlbg.jpg") repeat left top;
				@include font-size(14);
				padding: 6px 0;
				display: flex;
				flex-grow: 1;
				justify-content: center;
				&.watcharea {
					width: 31%;
				}
				&.dept {
					width: 27.73%;
				}
				&.ch {
					width: 23.27%;
				}
				&.tel {
					width: 18%;
				}
			}
		}
	}
	table {
		border-top: 1px solid #3f3f3f;
		border-right: 1px solid #3f3f3f;
		margin-bottom: 10px;
		width: 100%;
		.watcharea {
			width: 31%;
			@media screen and (max-width: 768px) {
				&:before {
					content: "視聴可能エリア";
				}
			}
		}
		.dept {
			width: 27.73%;
			@media screen and (max-width: 768px) {
				&:before {
					content: "局名";
				}
			}
		}
		.ch {
			width: 23.27%;
			@media screen and (max-width: 768px) {
				&:before {
					content: "ch番号";
				}
			}
		}
		.tel {
			width: 18%;
			@media screen and (max-width: 768px) {
				&:before {
					width: 28.2%;
					background: #e7e6e6;
					content: "お問い合わせ";
					font-size: 11px;
					padding: 2px 0;
					position: absolute;
					top: 4%;
					left: 3.5%;
					text-align: center;
				}
				p {
					width: 66%;
					float: right;
					text-align: left;
					line-height: 1.3 !important;
				}
			}
		}
		tr {
			td {
				border-left: 1px solid #3f3f3f;
				border-bottom: 1px solid #3f3f3f;
				box-sizing: border-box;
				font-size: 14px;
				padding: 10px;
				text-align: center;
				line-height: 1.2 !important;
				&.tl {
					text-align: left;
				}
				&.tel {
					border-bottom: 1px solid #3f3f3f;
					@media screen and (max-width: 768px) {
						width: 100%;
						padding: 4px 5px 8px 10px;
						margin-bottom: 6px;
						position: relative;
						&.last {
							margin-bottom: 0 !important;
						}
					}
				}
				a {
					color: #bc0d17;
					text-decoration: underline;
				}
			}
		}
		@media screen and (max-width: 768px) {
			border-left: 1px solid #3f3f3f;
			border-collapse: separate;
			padding: 6px 0 0;
			.watcharea, .dept, .ch {
				width: 67%;
				position: relative;
				@-moz-document url-prefix() {
					display: flex;
				}
				&:before {
					width: 42%;
					background: #e7e6e6;
					font-size: 11px;
					padding: 2px 0;
					position: absolute;
					top: 4%;
					left: -44%;
					text-align: center;
				}
			}
			tr {
				td {
					width: 67%;
					border: none;
					box-sizing: border-box;
					font-size: 11px;
					float: right;
					text-align: left;
					padding: 4px 10px 4px 10px;
				}
			}
		}
	}
}