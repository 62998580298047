#page-year-range {
	padding-top: 32px;
	.top-ttl {
		font-size: 34px;
		font-size: 3.4rem;
		@media screen and (min-width: 769px) {
			margin-bottom: 10px;
		}
		@media (max-width: 768px) {
			font-size: 30px;
			font-size: 3rem;
		}
	}
}