.page-shicho_inquiry {
  @include font-size(14);
  #inquiryWrap {
    .title {
      clear: both;
      padding: 1em;
      background: url(/images/common/bg_gradation_gray.jpg) repeat-x;
      border: none;
      border: 1px solid #ccc;
      font-weight: bold;
    }
    .contact {
      font-weight: bold;
      margin: 0.5em 1em 1em 1em;
      padding: 1em 1em 0.5em 1em;
      border-bottom: 1px dotted #ccc;
    }
    table {
      width: 92%;
      margin: 0 auto 1em auto;
      border-top: 1px solid #ccc;
      border-right: 1px solid #ccc;
      box-sizing: border-box;
      th {
        padding: 0.5em;
        border-left: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
        font-weight: bold;
        white-space: nowrap;
        background: #F1F1F1;
        text-align: center;
        @media screen and (max-width: 480px){
          display: block;
          width: 100%;
          text-align: left;
          padding: 1em;
        }
      }
      td {
        padding: 1em;
        border-left: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
        @media screen and (max-width: 480px){
          display: block;
          width: 100%;
        }
        a {
          word-break: break-all;
        }
      }
    }
    .inquiryBox {
      background: url(/images/common/bg_box_redline.gif) repeat-x;
      background-color: #fff;
      margin: 1em auto 1.5em auto;
      border: 1px solid #ccc;
      padding-top: 1px;
      .txt {
        width: 85%;
        margin: 0 auto 1em auto;
        text-align: center;
        padding: 0.5em;
      }
    }
    .notes {
      width: 92%;
      display: inherit;
      margin: 0.5em auto 0 auto;
      p {
        &.kome {
          text-indent: -1em;
          margin: 0 0 0 1em;
        }
        a {
          text-decoration: underline;
          color: #000;
          &:hover {
            color: #bc0d17;
          }
        }
      }
    }
    .inquiryBox_notes {
      margin-bottom: 1.5em;
      .inquiryBox2 {
        background: url(../../images/common/bg_box_redline.gif) repeat-x;
        background-color: #fff;
        margin: 1em auto 0.5em auto;
        border: 1px solid #ccc;
        padding-top: 1px;
        overflow: hidden;
      }
    }
  }
}