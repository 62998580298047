.category-view {
	background-color: #fbf4d8;
	padding: 6px 20px;
	margin-bottom: 20px;
	@include font-size(18);
	a {
		color: #977c10;
		@include font-size(18);
		font-weight: 500;
	}
	@media screen and (max-width: 768px) {
		@include font-size(14);
		a {
			@include font-size(14);
		}
	}
}

.article-heading {
	border-bottom: 1px solid #cccccc;
	display: block;
	position: relative;
	margin-bottom: 30px;
	padding-bottom: 15px;
	.article-heading-ttl {
		.txt-info {
			color: #333333;
			line-height: 1.2;
			font-weight: 500;
			&.txt-info-sm {
				@include font-size(16);
				@media screen and (max-width: 768px) {
					@include font-size(14);
				}
			}
			&.txt-info-lg {
				@include font-size(42);
				@media screen and (max-width: 768px) {
					@include font-size(31);
				}
			}
			&.txt-info-md {
				@include font-size(26);
				@media screen and (max-width: 768px) {
					@include font-size(19);
				}
			}
		}
	}
	.social-row {
		position: absolute;
		top: 0;
		right: 0;
		@media screen and (max-width: 768px) {
			position: relative;
			top: auto;
			right: auto;
			margin-bottom: 30px;
		}
	}
	.movie-genre {
		position: absolute;
		right: 0;
		bottom: 15px;
		@media screen and (max-width: 768px) {
			position: relative;
			right: auto;
			bottom: auto;
			margin-top: 10px;
		}
		&.movie-genre-underline {
			a:hover {
				text-decoration: underline;
			}
		}
	}
}

.social-row {
	display: flex;
	margin: 0 -5px;
	.social-row-items {
		margin: 0 5px;
		@include smooth-transition;
		&:hover {
			opacity: .7;
			@include smooth-transition;
		}
	}
}

.movie-genre {
	color: #333333;
	font-weight: 500;
	@include font-size(16);
}

.article-quick-info {
	margin-bottom: 40px;
	.article-images {
		border-right: 1px solid #cccccc;
		padding-right: 14px;
		float: left;
		width: 65.4%;
		@media screen and (max-width: 768px) {
			width: 100%;
			padding-right: 0;
			border-right: none;
			margin-bottom: 35px;
		}
	}
	.article-desc {
		float: left;
		width: 34.6%;
		padding-left: 24px;
		@media screen and (max-width: 768px) {
			width: 100%;
			padding-left: 0;
		}
		.tags {
			.tag-item {
				display: inline-block;
				margin-right: 5px;
				margin-bottom: 5px;
			}
			.tag-desc {
				display: block;
				margin-bottom: 20px;
				a {
					display: inline-block;
					padding-left: 15px;
					position: relative;
					@include font-size(14);
					&:before {
						display: block;
						content: '\f138';
						font-family: FontAwesome, sans-serif;
						position: absolute;
						top: -1px;
						left: 0;
						@include font-size(15);
					}
				}
				&.tag-desc-underline {
					a:hover {
						text-decoration: underline;
					}
				}
			}
		}
	}
}

.article-images {
	p {
		color: #333333;
		font-weight: 500;
		@include font-size(20);
		@media screen and (max-width: 768px) {
			@include font-size(17);
		}
	}
}

.article-sub-ttl {
	background-color: #f1f1f1;
	border-left: 4px solid #977c10;
	color: #333333;
	@include font-size(18);
	font-weight: 500;
	padding: 7px 14px;
	margin-bottom: 30px;
	@media screen and (max-width: 768px) {
		padding: 5px 10px;
		@include font-size(16);
		margin-bottom: 20px;
	}
}

.program-info {
	margin-bottom: 20px;
	tr {
		th {
			width: 110px;
			vertical-align: top;
			@media screen and (max-width: 768px) {
				width: 98px;
			}
			span {
				display: block;
				background-color: #f1f1f1;
				text-align: center;
				padding: 2px 10px;
				color: #333333;
				font-weight: 500;
				@include font-size(15);
				@media screen and (max-width: 768px) {
					padding: 5px;
					@include font-size(14);
				}
			}
		}
		td {
			vertical-align: top;
			padding: 2px 10px;
			color: #333333;
			font-weight: 500;
			@include font-size(16);
			@media screen and (max-width: 768px) {
				@include font-size(14);
			}
			a {
				&:hover {
					text-decoration: underline;
				}
			}
		}
		&.spacer {
			height: 5px;
		}
	}
}

.desc-notice {
	color: #333333;
	font-weight: 500;
	line-height: 1.3;
	@include font-size(14);
	@media screen and (max-width: 768px) {
		@include font-size(13);
	}
}

.slideshow-main, .slideshow-thumbs {
	.slick-slide {
		img {
			width: 100%;
			height: 100%;
		}
	}

}

.slideshow-thumbs {
	li {
		cursor: pointer;
		background-color: rgba(0, 0, 0, 0.9);
		.bg {
			display: inline-block;
			background: #000;
			vertical-align: middle;
			&:hover {
				opacity: .8;
				@include smooth-transition;
			}
		}
	}
}

.slideshow-main {
	margin-bottom: 10px;
	.slick-slide {
		margin-right: 10px;
	}
	@media screen and (max-width: 768px) {
		margin-bottom: 0;
		.slick-dots {
			position: relative;
			top: auto;
			bottom: auto;
			padding: 15px 0;
			li {
				top: -3px;
				button {
					padding: 4px;
				}
			}
		}
		.slick-slide {
			margin-right: 0;
		}
		.slick-arrow {
			border-color: #977c10;
			z-index: 1;
			padding: 0;
			width: 20px;
			height: 20px;
			&.slick-prev {
				top: 45%;
				transform: rotate(-135deg) translateX(40%);
			}
			&.slick-next {
				top: 40%;
				transform: rotate(45deg) translateX(40%);
			}
		}
	}
}

.slideshow-thumbs {
	margin-bottom: 45px;
	@media screen and (max-width: 768px) {
		display: none !important;
	}
	.slick-slide {
		margin-right: 10px;
		&.slick-current {
			> div {
				> li {
					display: block;
					position: relative;
					img {
						opacity: 0.7;
						@include smooth-transition;
					}
				}
			}
		}
	}
}

.slick-slide {
	outline: none !important;
}

.txt-basic {
	color: #333333;
	margin-bottom: 15px;
	font-weight: 500;
	line-height: 1.75;
	@include font-size(16);
	&.txt-sm {
		@include font-size(14);
	}
	@media screen and (max-width: 768px) {
		@include font-size(14);
		&.txt-sm {
			@include font-size(13);
		}
	}
	&.txt-basic-fix {
		@media(max-width: 768px) {
			margin-bottom: 0;
		}
	}
}

.txt-orange {
	color: #d63200;
}

.txt-red {
	color: red;
	&02 {
		color: #c02;
	}
}

.txt-left {
	text-align: left;
}

.table-container {
	@media screen and (max-width: 768px) {
		overflow: auto;
		-webkit-overflow-scrolling: touch;
	}
}

.movie-schedule-table {
	margin-bottom: 15px;
	width: 100%;
	border: 1px solid #aaaaaa;
	@media screen and (max-width: 640px) {
		margin-bottom: 40px;
	}
	thead {
		th {
			text-align: center;
			background-color: #333333;
			color: white;
			@include font-size(14);
			font-weight: 500;
			border: none;
			@media screen and (max-width: 768px) {
				@include font-size(15);
				border: 1px solid #aaaaaa;
			}
		}
	}
	tbody {
		td {
			vertical-align: top;
			border: 1px solid #aaaaaa;
			padding: 7px 20px;
			@include font-size(16);
			.datetime {
				color: #977c10;
			}
			a:hover {
				text-decoration: underline;
			}
			@media screen and (max-width: 768px) {
				padding: 8px 10px;
				@include font-size(14);
			}
		}
	}
}

.article-copyright {
	color: #999999;
	padding-top: 10px;
	padding-bottom: 90px;
	@include font-size(10);
	border-top: 1px solid #cccccc;
	@media screen and (max-width: 768px) {
		padding-bottom: 70px;
	}
}

.see-it-together {
	padding-top: 37px;
}

.movie-list-box {
	.movie-list-box-item {
		display: block;
		float: left;
		width: 23.75%;
		margin-right: 1.6%;
		margin-bottom: 1.6%;
		@include smooth-transition;
		&:nth-of-type(4n) {
			margin-right: 0;
		}
		@media screen and (max-width: 768px) {
			width: 100%;
			margin-right: 0;
			margin-bottom: 0;
			padding: 0 55px;
		}
		.img {
			background-color: #cccccc;
			img {
				display: block;
				margin: 0 auto;
				@media screen and (max-width: 768px) {
					width: 100%;
				}
			}
		}
		.ttl {
			background-color: #f8f8f8;
			padding: 10px 12px;
			color: #333333;
			@include font-size(19);
			text-transform: uppercase;
			min-height: 68px;
			line-height: 1.2;
		}
		&:hover {
			opacity: .7;
			@include smooth-transition;
		}
	}
	@media screen and (max-width: 768px) {
		margin-bottom: 60px;
		.movie-list-box-item {
			.ttl {
				background-color: #f8f8f8;
				@include font-size(14);
			}
		}
		.slick-arrow {
			border-color: #977c10;
			z-index: 1;
		}
		.slick-current {
			+ .slick-slide {
				margin-left: -95px;
			}
		}
	}
}

.see-it-together {
	@media screen and (max-width: 768px) {
		.movie-list-box {
			position: relative;
			left: -20px;
			width: calc(100% + 40px);
			.slick-arrow {
				&.slick-prev {
					left: 20px;
				}
				&.slick-next {
					right: 20px;
				}
			}
		}
	}
}

.broadcast-schedule {
	font-weight: 500;
	ul {
		li {
			margin-bottom: 10px;
		}
	}
	&-calendar {
		float: left;
		width: 390px;
		.txt-nummonth {
			@include font-size(26);
		}
		.txt-month {
			@include font-size(22);
		}
		.table-calendars {
			.broadcast-day {
				background: #fbf4d8;
				&:hover {
					background: #fbf4d8;
					a {
						text-decoration: underline;
						color: #977c10 !important;
					}
				}
				&.color-blue {
					a {
						color: #7db4ff;
					}
					&:hover {
						a {
							color: #7db4ff !important;
						}
					}
				}
			}
		}
	}
	&-table {
		float: right;
		width: calc(100% - 420px);
		@media screen and (max-width: 768px) {
			width: 100%;
			.movie-schedule-table {
				min-width: auto;
				margin-bottom: 20px;
				thead th {
					border: 1px solid #aaaaaa;
				}
			}
		}
		&-ttl {
			padding-bottom: 15px;
			line-height: 1;
			@include font-size(18);
		}
	}
}

.calender-note {
	margin: 5px 0 15px;
	&-box {
		width: 26px;
		height: 26px;
		margin-right: 8px;
		display: inline-block;
		border: 1px solid #aaaaaa;
		background: #fbf4d8;
		vertical-align: middle;
	}
	&-txt {
		line-height: 26px;
		vertical-align: middle;
		display: inline-block;
		@include font-size(14);
	}
}

.page-detail {
	@media screen and (max-width: 768px) {
		.filtered-item {
			padding: 40px 0 35px 0;
			.filtered-item-file {
				float: none;
			}
		}
		.notice {
			margin: 0;
			&.notice-sm {
				margin-top: 10px;
			}
		}
	}
	.article-quick-info {
		.article-desc {
			.tags {
				.tag-item {
					a {
						img {
							@media screen and (max-width: 768px) {
								width: auto;
								height: 23px;
							}
						}
					}
				}
			}
		}
	}
	.category-view {
		a {
			letter-spacing: -0.5px;
			&:hover {
				text-decoration: underline;
			}
		}
	}
	.article-heading {
		@media screen and (min-width: 769px) {
			padding-right: 250px;
		}
		.article-heading-ttl {
			.txt-info-sm {
				letter-spacing: -1.3px;
			}
			.txt-info-lg {
				letter-spacing: -3.2px;
			}
			.txt-info-md {
				letter-spacing: -2.3px;
			}
		}
	}
	.top-ttl {
		margin-bottom: 19px;
		@media screen and (max-width: 768px) {
			margin-bottom: 24px;
		}
	}
}