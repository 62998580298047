.bg-page {
	background: url("../images/forum/page_bg.jpg");
}

.mainTitle {
	background: url(../images/forum/main_tit_bg.jpg) 0 0 no-repeat;
	background-size: 100%;
	color: #d4be64;
	font-size: 18px;
	font-weight: bold;
	letter-spacing: 0.1em;
	text-align: center;
	padding: 24px 0;
}

.show-box {
	background: #efedd7;
	border: 2px solid #8e9184;
	border-top: none;
	margin-bottom: 30px;
	padding: 18px;
	@include font-size(14);
	@media screen and (max-width: 768px){
		padding: 15px;
	}
}

.box-item {
	background: white;
	padding: 20px 30px;
	margin-top: 20px;
	border-radius: 4px;
	border: 1px solid #d1d1d1;
	@include font-size(13);
	@media screen and (max-width: 768px){
		padding: 15px;
	}
	p {
		margin-bottom: 10px;
		line-height: 1.53;
		&:last-child {
			margin-bottom: 0;
		}
	}
}

.w100 {
	width: 100%;
}
.mb10{
	margin-bottom: 10px;
}
.mb20{
	margin-bottom: 20px;
}
.mb90{
	margin-bottom: 90px;
	@media screen and (max-width: 768px){
		margin-bottom: 45px;
	}
}

.linkBlue {
	color: #0087ba;
	text-decoration: underline;
}

.color-blue {
	color: #0000ff;
}

.note-box {
	color: #977c10;
	&.note-box-fix{
		color: #C00;
	}
}

.note-item {
	background: #efedd7;
	margin: 20px 0 0 0;
	padding: 15px 20px;
	@include font-size(11);
	line-height: 1.8;
}

.userBoxwrap {
	border: 1px solid #baa242;
	margin: 20px 0 0 0;
	.ttl {
		background: #eef1df;
		margin: 0;
		padding: 15px;
		.handleTitle {
			float: left;
			@include font-size(11);
			strong{
				@include font-size(15);
			}
			@media screen and (max-width: 768px){
				width: 100%;
				margin-bottom: 5px;
			}
		}
		.handleDay {
			float: right;
			width: 120px;
			text-align: right;
			@include font-size(12);
			@media screen and (max-width: 768px){
				float: left;
				width: 100%;
				text-align: left;
			}
		}
		.handleName {
			float: right;
			width: 260px;
			@include font-size(13);
			@media screen and (max-width: 1000px){
				width: 100%;
				display: inline-block;
			}
			span{
				@include font-size(11);
			}
			@media screen and (max-width: 768px){
				float: left;
				width: 100%;
			}
		}
	}
	.txt {
		border-top: 1px solid #baa242;
		background: #fff;
		padding: 15px 20px;
		@include font-size(13);
		@media screen and (max-width: 768px){
			padding: 15px;
		}
		a.color-blue {
			font-weight: bold;
			text-decoration: underline;
			word-break: break-all;
		}
		p {
			margin-bottom: 10px;
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
}

.pagination-user {
	display: inline-block;
	li {
		float: left;
		padding: 0 3px;
		a {
			text-decoration: underline;
			color: #333;
			&:hover {
				color: #bc0d17;
			}
		}
		&.active {
			a {
				text-decoration: none;
			}
		}
	}
}
.sidebar-list{
	background: #eee url(../images/forum/pr_bg.jpg) 0 0;
	margin-top: 20px;
	.img{
		padding: 0 10px 5px 10px;
		img{
			margin-bottom: 10px;
			width: 100%;
		}
		@media screen and (max-width: 768px) {
			padding: 10px 10px 0 10px;
			a{
				display: block;
				width: 48%;
				&.left-img{
					float: left;
				}
				&.right-img{
					float: right;
				}
			}
		}
		ul{
			margin-top: 10px;
			@media screen and (max-width: 768px) {
				margin-top: 0;
				li{
					float: left;
					width: 48%;
					&:nth-child(2n){
						float: right;
					}
					a{
						width: 100%;
					}
				}
			}
			&.img-sp{
				margin-top: 0;
				li{
					margin-bottom: 15px;
				}
			}
		}
	}
	&.not-bg{
		background: none;
		margin-top: 0;
		.img{
			padding: 0;
			a{
				img{
					margin-bottom: 15px;
				}
				&:last-child{
					img{
						margin-bottom: 0;
					}
				}
			}
		}
	}
}
#lNav{
	margin-bottom: 20px;
}
.about-houjin{
	@include font-size(13);
}
.target{
	margin: 20px 20px 25px;
	@media screen and (max-width: 768px) {
		margin: 20px 0px 25px;
	}
	dl{
		border-bottom: 1px dotted #999;
		overflow: hidden;
		dt{
			float: left;
			width: 100px;
			padding: 10px 0 10px 10px;
			font-weight: bold;
			border-top: 1px dotted #999;
		}
		dd{
			float: left;
			width: calc(100% - 101px);
			margin-left: 1px;
			padding: 10px 10px 10px 0;
			border-top: 1px dotted #999;
		}
	}
}
.img-houjin{
	display: inline-block;
	margin-bottom: 50px;
	li{
		float: left;
		width: 48%;
		&:nth-child(2){
			float: right;
		}
	}
}
.txt-right{
	text-align: right;
}
.txt-left{
	text-align: left;
}
.txt-bold{
	font-weight: 700;
}