.faqBox_white {
	background-color: #FFF;
	border: 1px solid #ccc;
	border-top: none;
	overflow: hidden;
	padding: 10px;
}

.faqBox_grey {
	background-color: #eee;
	padding: 10px;
	margin-bottom: 30px;
}

.faqBox {
	margin: 0 0 10px 0;
}

.list-qa {
	li {
		float: left;
		width: 49%;
		margin-right: 2%;
		padding: 5px 0;
		&:nth-child(2n) {
			margin-right: 0;
		}
		@media(max-width: 768px) {
			width: 100%;
		}
		a {
			padding-left: 18px;
			color: #f5616a;
			line-height: 1.4;
			text-decoration: underline;
			&:hover {
				color: #0087ba;
			}
		}
		a.arrowRed {
			background: url(../images/shicho/shichio-faq/arrow_red.gif) left center no-repeat;
			display: block;
		}
	}
}

.show-box-faq {
	.accordion02 {
		a {
			text-decoration: underline;
			color: #000;
			&:hover {
				color: #bc0d17;
			}
		}
	}
}

.page-shicho-faq {
	#bannerArea {
		a {
			display: inline-block;
			&:hover {
				opacity: .8;
			}
		}
	}
	.pageTop{
		.imgWrap{
			display: inline-block;
		}
	}
}