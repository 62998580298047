.txt-top-box {
	border: 4px solid #e4e4e4;
	margin: 20px 0 25px;
	padding: 5px;
	@include font-size(13);
	color: #000;
}

.table-trouble {
	width: 100%;
	border: 1px solid #CCCCCC;
	tr {
		border-bottom: 1px solid #CCCCCC;
	}
	th, td {
		background: #ffffff;
		background: -moz-linear-gradient(top, #ffffff 0%, #f0f0f0 100%);
		background: -webkit-linear-gradient(top, #ffffff 0%, #f0f0f0 100%);
		background: linear-gradient(to bottom, #ffffff 0%, #f0f0f0 100%);
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#f0f0f0', GradientType=0);
		padding: 15px;
		word-break: break-all;
	}
	th {
		font-weight: bold;
		border-right: 1px solid #CCCCCC;
		@include font-size(13);
	}
	td {
		color: #bc0d17;
		@include font-size(13);
		@include smooth-transition;
		a {
			color: #bc0d17;
			@include font-size(13);
			@include smooth-transition;
			&:hover {
				color: #0087ba;
				@include smooth-transition;
			}
		}
		&.icon {
			width: 46px;
			img {
				width: 20px;
				height: 20px;
			}
		}
	}
}

.table-desc {
	padding-bottom: 50px;
	margin-top: 10px;
	p {
		@include font-size(13);
	}
	ul {
		li {
			@include font-size(13);
		}
	}
}

#backFoot {
	border-top: 1px dotted #ccc;
	padding-top: 1.2em;
}