/* ====================================================
index
==================================================== */
.main-slider-wrap {
	margin-bottom: 30px;
}

.main-slider {
	li {
		display: table;
		width: 100%;
		&:focus {
			outline: none;
		}
		.img-col {
			display: table-cell;
			vertical-align: middle;
			width: 60%;
			@media screen and (max-width: 768px) {
				display: block;
				width: 100%;
				position: relative;
				overflow: hidden;
				&:before {
					content: "";
					display: block;
					padding-top: 55%;
				}
			}
			img {
				width: 100%;
				@media screen and (max-width: 768px) {
					position: absolute;
					top: 0;
					left: 0;
					bottom: 0;
					right: 0;
				}
			}
		}
		.text-col {
			position: relative;
			display: table-cell;
			vertical-align: middle;
			background: url("../images/index/bg_item.png") no-repeat;
			padding: 0 2%;
			@media screen and (max-width: 990px) {
				padding: 15px 1.5%;
			}
			@media screen and (max-width: 768px) {
				display: block;
				padding: 30px 20px 68px;
				background: url("../images/index/bg_item_sp.png") no-repeat;
				background-size: 100%;
			}
		}
		.type {
			color: #977c10;
			@include font-size(20);
			font-weight: bold;
			@media screen and (max-width: 1100px) {
				@include font-size(19);
			}
			@media screen and (max-width: 768px) {
				@include font-size(17);
			}
		}
		.ttl {
			@include font-size(32);
			margin-bottom: 7px;
			@media screen and (max-width: 1100px) {
				@include font-size(22);
				margin-bottom: 5px;
			}
			@media screen and (max-width: 768px) {
				margin-bottom: 10px;
			}
		}
		.intro {
			@include font-size(15);
			margin-bottom: 10px;
			@media screen and (max-width: 1100px) {
				@include font-size(13);
			}
			@media screen and (max-width: 768px) {
				display: none;
			}
		}
		.hour {
			@include font-size(24);
			padding-bottom: 60px;
			@media screen and (max-width: 1100px) {
				@include font-size(16);
				padding-bottom: 0;
			}
			@media screen and (max-width: 768px) {
				@include font-size(19);
			}
		}
		.more-btn {
			position: absolute;
			bottom: 25px;
			right: 20px;
			@media screen and (max-width: 1100px) {
				position: static;
				float: right;
				padding-top: 10px;
			}
			@media screen and (max-width: 768px) {
				display: none;
			}
		}
		&:hover {
			.img-col {
				img {
					opacity: 0.7;
					@include smooth-transition;
				}
			}
		}
	}
	@media screen and (max-width: 768px) {
		.slick-prev, .slick-next {
			margin-top: 0;
			top: 20%;
			width: 22px;
			height: 33px;
			border: none;
			background-repeat: no-repeat;
			background-size: 100% auto;
			-webkit-transform: rotate(0deg);
			transform: rotate(0deg);
			z-index: 5000;
		}
		.slick-prev {
			background-image: url(../images/index/s_prev.png);
			left: -13px;
		}
		.slick-next {
			background-image: url(../images/index/s_next.png);
			right: -13px;
		}
	}
}

.slick-item-new {
	position: relative;
	.slick-hover {
		position: absolute;
		@include v-align;
		@include smooth-transition;
		cursor: pointer;
		border: none;
		background: none;
		opacity: 0;
		visibility: hidden;
		outline: none;
		@media screen and (max-width: 768px) {
			display: none;
		}
		&.slick-prev-clone {
			left: -20px;
		}
		&.slick-next-clone {
			right: -20px;
		}
	}
	&:hover {
		.slick-hover {
			opacity: 1;
			visibility: visible;
			&.slick-prev-clone {
				left: 10px;
			}
			&.slick-next-clone {
				right: 10px;
			}
		}
	}
}

.thum-slider-wrap {
	position: relative;
	background-color: #423f35;
	padding: 12px 80px 15px;
	@media screen and (max-width: 768px) {
		display: none;
	}
	.slick-next {
		right: -45px;
	}
	.slick-prev {
		left: -45px;
	}
}

.thum-slider {
	li {
		cursor: pointer;
		&:focus {
			outline: none;
		}
		&.slick-current, &:hover {
			img {
				opacity: 0.7;
				@include smooth-transition;
			}
		}
		.bg {
			display: inline-block;
			background: #000;
			vertical-align: middle;
		}
	}
}

.news-list {
	border-bottom: 1px solid #cccccc;
	padding: 13px 0 0;
	@media screen and (max-width: 768px) {
		padding-top: 20px;
	}
	.news-list-ttl {
		float: left;
		margin-right: 4px;
		@media screen and (max-width: 768px) {
			float: none;
			margin-bottom: 0;
			margin-right: 0;
		}
	}
	.news-list-cont {
		float: left;
		width: calc(100% - 169px);
		@media screen and (max-width: 768px) {
			float: none;
		}
		.ticker-item {
			margin-bottom: 0;
		}
	}
	li {
		margin-bottom: 13px;
		@media screen and (max-width: 768px) {
			margin-bottom: 20px;
		}
		.cat, .day, .ttl {
			display: inline-block;
			@media screen and (max-width: 768px) {
				display: inline;
			}
		}
		.day {
			color: #333;
			margin-right: 20px;
			@media screen and (max-width: 768px) {
				margin-right: 10px;
			}
		}
		.cat {
			width: 120px;
			margin-right: 45px;
			text-align: center;
			@media screen and (max-width: 768px) {
				display: block;
				max-width: 120px;
				margin-bottom: 10px;
			}
			&.info {
				background: #f2dc22;
				color: #4b4300;
				font-weight: bold;
			}
			&.new {
				color: #666666;
				@include font-size(18);
				font-weight: bold;
				@media screen and (max-width: 768px) {
					@include font-size(16);
					text-align: left;
				}
			}
		}
		.ttl {
			width: calc(100% - 280px);
			vertical-align: middle;
			@media(max-width: 768px) {
				width: 100%;
			}
		}
		a {
			color: $black;
			&:hover {
				text-decoration: underline;
			}
		}
	}
	&.info {
		border-bottom: none;
		li {
			color: #d63200;
			margin-bottom: 10px;
			a, .day {
				color: #d63200;
			}
		}
	}
}

.top-ttl {
	font-weight: 500;
	@include font-size(30);
	margin-bottom: 22px;
	@media screen and (max-width: 768px) {
		position: relative;
		@include font-size(26);
		line-height: 1.3;
		margin-bottom: 30px;
	}
	&::before {
		content: "";
		display: inline-block;
		vertical-align: middle;
		background-repeat: no-repeat;
		background-size: 100% auto;
		margin-right: 10px;
		@media screen and (max-width: 768px) {
			position: absolute;
			left: 0;
			top: 50%;
			margin: 0;
			-webkit-transform: translateY(-50%);
			-ms-transform: translateY(-50%);
			transform: translateY(-50%);
		}
	}
	&.program {
		@media screen and (max-width: 768px) {
			padding-left: 58px;
		}
		&::before {
			background-image: url(../images/index/recommend_ico.png);
			width: 48px;
			height: 63px;
		}
	}
	&.tw {
		@media screen and (max-width: 768px) {
			padding-left: 64px;
		}
		&::before {
			background-image: url(../images/index/twitter_ico.png);
			width: 54px;
			height: 46px;
		}
	}
	&.onair {
		@media screen and (max-width: 768px) {
			padding-left: 65px;
			margin-bottom: 40px;
		}
		&::before {
			background-image: url(../images/index/onair_ico.png);
			width: 55px;
			height: 55px;
		}
	}
	&.p-search {
		@media screen and (max-width: 768px) {
			padding-left: 67px;
		}
		&::before {
			background-image: url(../images/index/program_ico.png);
			width: 57px;
			height: 41px;
		}
	}
	&.plan {
		@media screen and (max-width: 768px) {
			padding-left: 67px;
		}
		&::before {
			background-image: url(../images/index/plan_ico.png);
			width: 57px;
			height: 45px;
			top: 41% !important;
		}
	}
	&.special {
		@media screen and (max-width: 768px) {
			padding-left: 66px;
			margin-bottom: 45px;
		}
		&::before {
			background-image: url(../images/index/special_ico.png);
			width: 56px;
			height: 61px;
			top: 46% !important;
		}
	}
	&.schedule {
		&::before {
			background-image: url(../images/timetable/schedule_ico.png);
			width: 56px;
			height: 44px;
		}
	}
	&.special_02 {
		margin-bottom: 28px;
		@media screen and (max-width: 768px) {
			margin-bottom: 40px !important;
			padding-left: 66px;
		}
		&::before {
			background-image: url(../images/index/Special_ico_02.png);
			width: 55px;
			height: 64px;
		}
	}
	&.daily-icon{
		&:before {
			background-image: url(../images/index/daily_ico.png);
			width: 56px;
			height: 46px;
		}
	}
	&.noicon {
		&:before {
			display: none;
		}
	}
	&.top-ttl-fix{
		@include font-size(34);
		@media screen and (max-width: 768px) {
			@include font-size(26);
		}
	}
}

.program-box {
	.left-col {
		width: 71.44%;
		float: left;
		background: #333;
		@media screen and (max-width: 768px) {
			width: 100%;
			float: none;
			background: none;
			height: inherit !important;
		}
	}
	.right-col {
		width: 27.13%;
		margin-left: 1.43%;
		float: right;
		@media screen and (max-width: 768px) {
			width: 100%;
			float: none;
			margin: 0;
		}
	}
	.box {
		margin-bottom: 12px;
		background: #333;
		@media screen and (max-width: 768px) {
			margin-bottom: 15px;
			display: table;
			width: 100%;
			height: 90px !important;
		}
		.img {
			@media screen and (max-width: 768px) {
				position: relative;
				display: table-cell;
				width: 135px;
				overflow: hidden;
				vertical-align: top;
			}
			img {
				width: 100%;
				@media screen and (max-width: 768px) {
					position: absolute;
					display: block;
					margin: auto;
					top: 0;
					left: 0;
					bottom: 0;
					right: 0;
					max-width: inherit;
					width: 150px;
				}
			}
		}
		.ttl {
			color: #FFF;
			line-height: 1.3;
			padding: 13px 9px;
			@include font-size(15);
			@media screen and (max-width: 768px) {
				@include font-size(14);
			}
			.big {
				@include font-size(24);
				@media screen and (max-width: 768px) {
					@include font-size(14);
				}
			}
			@media screen and (max-width: 768px) {
				display: table-cell;
			}
		}
	}
}

.tw-frame {
	border: 1px solid #CCC;
	border-radius: 3px;
	margin-top: 27px;
	margin-bottom: 10px;
	&-fix {
		margin-top: 22px !important;
	}
	@media screen and (max-width: 768px) {
		margin-bottom: 30px;
	}
}

.mallmaga-btn {
	text-align: center;
	a {
		border-radius: 3px;
		padding: 11px 0;
		display: block;
		background-color: #977c10;
		color: #FFF;
		@include font-size(22);
		font-weight: bold;
		@media screen and (max-width: 768px) {
			position: relative;
			@include font-size(19);
			padding: 17px 0;
		}
		&::before {
			content: "";
			width: 44px;
			height: 35px;
			display: inline-block;
			vertical-align: middle;
			background-repeat: no-repeat;
			background-size: 100% auto;
			margin-right: 10px;
			background-image: url(../images/index/mailmaga_ico.png);
			@media screen and (max-width: 768px) {
				position: absolute;
				top: 50%;
				left: 5%;
				-webkit-transform: translateY(-50%);
				-ms-transform: translateY(-50%);
				transform: translateY(-50%);
				width: 35px;
				height: 27px;
			}
		}
	}
}

.border-scrollbar {
	@media(min-width: 769px) {
		position: relative;
		display: inline-block;
		width: 100%;
		&:after {
			content: "";
			height: 2px;
			background: #fff;
			bottom: 3px;
			z-index: 0;
			left: 0;
			position: absolute;
			right: 0;
		}
	}
}

.onair-box {
	position: relative;
	overflow: auto;
	padding: 5px 0 15px;
	z-index: 1;
	&::-webkit-scrollbar {
		height: 8px;
	}
	/*バーの太さ*/
	&::-webkit-scrollbar-track {
		background: transparent;
	}
	/*バーの背景色*/
	&::-webkit-scrollbar-thumb {
		background: #977c10;
	}
	/*バーの色*/
}

.onair-list {
	width: 3000px;
	@media screen and (max-width: 768px) {
		width: inherit;
		margin-bottom: 20px;
	}
	li {
		float: left;
		margin-right: 18px;
		@include font-size(16);
		line-height: 1.3;
		@media screen and (max-width: 768px) {
			@include font-size(14);
			margin-right: 0;
			float: none;
		}
		.ttl {
			margin-top: 10px;
			color: #333;
		}
		img {
			width: 100%;
			max-height: 149px;
			@media screen and (max-width: 768px) {
				max-height: 100%;
			}
		}
		&:hover {
			opacity: 0.7;
			@include smooth-transition;
		}
	}
}

.plan-list {
	li {
		width: 32.16%;
		float: left;
		margin: 0 1.76% 20px 0;
		@media screen and (max-width: 768px) {
			width: 100%;
			margin: 0 0 20px;
		}
		&:nth-of-type(3n) {
			margin-right: 0;
		}
	}
}

.plan-list-slide {
	.slick-prev, .slick-next {
		position: absolute;
		@include v-align;
		@include smooth-transition;
		cursor: pointer;
		border: none;
		height: 30px;
		width: 30px;
		border-radius: 50%;
		opacity: 0;
		visibility: hidden;
		outline: none;
		@media screen and (max-width: 768px) {
			display: none;
		}
	}
	&:hover {
		.slick-prev, .slick-next {
			opacity: 1;
			visibility: visible;
		}
		.slick-prev {
			left: 15px !important;
		}
		.slick-next {
			right: 15px !important;
		}
	}
	.slick-prev {
		background: url(../images/index/s_prev.png) no-repeat center center;
		left: 0px !important;
		z-index: 9;
	}
	.slick-next {
		background: url("../images/index/s_next.png") no-repeat center center;
		right: -10px !important;
	}
	.slick-dots {
		bottom: -10px;
		top: auto !important;
		li {
			margin: 0 10px;
			button {
				padding: 4px;
			}
		}
	}
	.plan-list {
		display: inline-block;
		width: 100%;
		li {
			width: calc(94% / 3);
			float: left;
			margin-left: 2%;
			margin-bottom: 20px;
			margin-right: 0;
			@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
				margin-left: 1.9%;
			}
		}
	}
}

.plan-box {
	background-color: #333;
	.img {
		position: relative;
		width: 100%;
		background-color: #cccccc;
		overflow: hidden;
		&::before {
			content: "";
			display: block;
			padding-top: 66%
		}
		img {
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			margin: auto;
		}
	}
	.txt {
		position: relative;
		background: #333333;
		color: #FFF;
		padding: 15px 10px 10px;
		@include font-size(16);
		@media screen and (max-width: 768px) {
			@include font-size(14);
		}
		&.txt-program-col {
			padding: 5px 10px 10px;
			.ttl {
				@include font-size(22);
				line-height: 1.4;
			}
		}
	}
	.ttl {
		@include font-size(19);
		@media screen and (max-width: 768px) {
			@include font-size(17);
		}
	}
	.cat {
		position: absolute;
		top: -25px;
		left: -10px;
		width: 140px;
		padding: 5px 0;
		text-align: center;
		background-color: #a78718;
		&::before {
			position: absolute;
			top: -10px;
			left: 0;
			content: "";
			display: block;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 0 0 10px 10px;
			border-color: transparent transparent #705a0f transparent;
		}
		&.blue {
			background-color: #00a0eb;
			&::before {
				border-color: transparent transparent #005075 transparent;
			}
		}
		&.green {
			background-color: #2dc100;
			&::before {
				border-color: transparent transparent #166000 transparent;
			}
		}
		&.pink {
			background-color: #ff4b86;
			&::before {
				border-color: transparent transparent #7f2543 transparent;
			}
		}
		&.orange {
			background-color: #ff7200;
			&::before {
				border-color: transparent transparent #7f3900 transparent;
			}
		}
		&.blue-green {
			background-color: #3c7827;
			&::before {
				border-color: transparent transparent #254918 transparent;
			}
		}
		&.purple {
			background-color: #536da2;
			&::before {
				border-color: transparent transparent #2c4069 transparent;
			}
		}
		&.purple-light {
			background-color: #8a5496;
			&::before {
				border-color: transparent transparent #54365a transparent;
			}
		}
	}
	&:hover {
		opacity: 0.7;
		@include smooth-transition;
	}
	&.plan-box-02 {
		&:hover {
			opacity: 1;
			@include smooth-transition;
			.txt {
				text-decoration: underline;
			}
		}
	}
}

.plan-list-sp {
	@media screen and (max-width: 768px) {
		.plan-box {
			.img {
				width: 135px;
				float: left;
				@media screen and (max-width: 350px) {
					width: 105px;
				}
			}
			.txt {
				width: calc(100% - 135px);
				float: left;
				@media screen and (max-width: 768px) {
					padding-top: 13px;
				}
				@media screen and (max-width: 350px) {
					width: calc(100% - 105px);
					padding: 5px 10px;
				}
				.ttl {
					@include font-size(14);
				}
			}
		}
	}
}

.special-list {
	li {
		width: 23.75%;
		float: left;
		margin-left: 1.6666%;
		background-color: rgba(255, 255, 255, 0.5);
		@media screen and (max-width: 768px) {
			min-height: initial;
			width: 48%;
			margin: 0 4% 15px 0 !important;
			&:nth-of-type(2n) {
				margin-right: 0 !important;
			}
			img {
				width: 100%;
			}
		}
		&:first-of-type {
			margin-left: 0;
		}
		.txt {
			padding: 8px 10px;
			color: $black;
		}
		.sub-ttl {
			@include font-size(15);
			@media screen and (max-width: 768px) {
				@include font-size(13);
			}
		}
		.ttl {
			@include font-size(19);
			@media screen and (max-width: 768px) {
				@include font-size(16);
			}
		}
		&:hover {
			opacity: 0.7;
			@include smooth-transition;
		}
	}
	&.col-3 {
		@include font-size(15);
		li {
			width: 32.16%;
			margin: 0 1.76% 20px 0;
			min-height: 261px;
			@media screen and (min-width: 0 \0
			) {
				min-height: 264px;
			}
			&:nth-child(3n) {
				margin-right: 0;
			}
			.txt {
				padding: 10px 0;
			}
			@media screen and (max-width: 768px) {
				min-height: initial;
				@media screen and (min-width: 0 \0
				) {
					min-height: initial;
				}
				.txt {
					@include font-size(13);
				}
				width: 48%;
				margin: 0 4% 15px 0 !important;
				&:nth-child(2n) {
					margin-right: 0 !important;
				}
			}
		}
	}
}

.search-wrap {
	.search-ttl {
		@include font-size(16);
		font-weight: bold;
		margin-bottom: 20px;
		@media screen and (max-width: 768px) {
			@include font-size(14);
			margin-bottom: 10px;
		}
		.big {
			@include font-size(22);
			@media screen and (max-width: 768px) {
				@include font-size(19);
			}
		}
	}
	.search {
		width: 44.2%;
		float: left;
		@media screen and (max-width: 768px) {
			width: 100%;
			float: none;
			margin: 0 0 30px;
		}
	}
	.word {
		width: 54.7%;
		float: right;
		@media screen and (max-width: 768px) {
			width: 100%;
			float: none;
		}
	}
	.word-ttl {
		float: left;
		position: relative;
		background: #b1b1b1;
		height: 68px;
		color: #FFF;
		text-align: center;
		line-height: 1.4;
		@include font-size(14);
		width: 85px;
		margin-right: 20px;
		font-weight: bold;
		padding: 17px 0;
		@media screen and (max-width: 768px) {
			width: 100%;
			height: 30px;
			line-height: 30px;
			float: none;
			margin: 0 0 20px;
			padding: 0;
		}
		&::after {
			position: absolute;
			top: 0;
			right: -10px;
			content: "";
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 34px 0 34px 10px;
			border-color: transparent transparent transparent #b1b1b1;
			@media screen and (max-width: 768px) {
				display: none;
			}
		}
	}
	.word-list {
		overflow: hidden;
	}
	.s-window {
		width: 100%;
		height: 68px;
		@include font-size(24);
		@media screen and (max-width: 990px) {
			@include font-size(19);
		}
		@media screen and (max-width: 768px) {
			height: 50px;
			@include font-size(18);
			padding-left: 18px;
		}
		@media screen and (max-width: 350px) {
			padding-left: 10px;
		}
	}
	.s-btn {
		right: 20px;
		width: 30px;
		height: 30px;
	}
	.keyword-area {
		padding-top: 15px;
		border-top: 1px solid #cccccc;
		margin-bottom: 25px;
		.s-window {
			border: 1px solid #999999;
		}
		@media screen and (max-width: 768px) {
			padding-top: 25px;
		}
	}
	.genre-area,
	.theme-area {
		padding-top: 15px;
		width: 49.4%;
		float: left;
		border-top: 1px solid #cccccc;
		@media screen and (max-width: 768px) {
			width: 100%;
			padding-top: 25px;
		}
	}
	.genre-area {
		margin-right: 1.2%;
		@media screen and (max-width: 768px) {
			margin: 0;
		}
	}
	.link-list {
		border-top: 1px solid #cccccc;
		padding-top: 15px;
		margin-bottom: 10px;
		li {
			display: inline-block;
			@include font-size(16);
			margin-right: 25px;
			&:last-child {
				margin-right: 0;
			}
			@media screen and (max-width: 768px) {
				@include font-size(14);
				margin-right: 15px;
			}
			a {
				color: $black;
				font-weight: bold;
				&::after {
					content: "";
					display: inline-block;
					margin-left: 5px;
					vertical-align: middle;
					margin-bottom: 4px;
					width: 8px;
					height: 8px;
					border-top: 2px solid #9c821b;
					border-right: 2px solid #9c821b;
					-ms-transform: rotate(45deg);
					transform: rotate(45deg);
				}
			}
			.big {
				@include font-size(22);
				@media screen and (max-width: 768px) {
					@include font-size(19);
				}
			}
		}
	}
	.theme-slide {
		padding: 0 25px;
		@media screen and (max-width: 768px) {
			padding: 0;
		}
		.ttl {
			@include font-size(16);
			padding-top: 5px;
			color: $black;
		}
	}
	.slick-prev,
	.slick-next {
		margin-top: -18px;
		width: 20px;
		height: 20px;
		border-color: $red;
	}
}

.word-list {
	li {
		display: inline-block;
		@include font-size(14);
		margin-bottom: 5px;
		margin-right: 3px;
		@media screen and (max-width: 768px) {
			margin: 0 7px 7px 0;
		}
		a {
			display: block;
			border-radius: 5px;
			background-color: #FFF;
			text-align: center;
			padding: 5px 7px;
			&:hover {
				background-color: #f6f6f6;
			}
			@media(max-width: 768px) {
				padding: 9px 7px;
			}
		}
	}
}

.genre-list {
	font-size: 0;
	li {
		display: inline-block;
		vertical-align: top;
		width: 23.64%;
		margin-right: 1.81%;
		margin-bottom: 10px;
		border-radius: 3px;
		@media screen and (max-width: 990px) {
			width: 32%;
			margin-right: 2% !important;
			&:nth-of-type(3n) {
				margin-right: 0 !important;
			}
		}
		&:nth-of-type(4n) {
			margin-right: 0;
		}
		@include font-size(14);
		a {
			padding: 15px 0;
			display: block;
			background-color: #FFF;
			text-align: center;
			&:hover {
				background-color: #f6f6f6;
			}
		}
	}
}

select.genre-list {
	width: 100%;
	margin-bottom: 40px;
	@include font-size(16);
}

.read-more-btn, .read-more-btn-2, .read-more-btn-3 {
	cursor: pointer;
	position: relative;
	text-align: center;
	color: #977c10;
	@include font-size(16);
	margin-bottom: 30px;
	&::after {
		content: "";
		position: absolute;
		bottom: -10px;
		left: 0;
		right: 0;
		margin: auto;
		vertical-align: middle;
		width: 6px;
		height: 6px;
		border-top: 1px solid #977c10;
		border-right: 1px solid #977c10;
		-ms-transform: rotate(135deg);
		transform: rotate(135deg);
	}
}

.read-more-area, .read-more-area-2, .read-more-area-3 {
	display: none;
}

#index {
	background: url(../images/index/bg_home.png) no-repeat center top;
	background-size: 100% auto;
	@media screen and (max-width: 768px) {
		background-image: url(../images/index/bg_home_sp.png);
	}
	.info-box {
		background-color: rgba(255, 255, 255, 0.85);
		padding: 7px 20px 12px;
		@include font-size(16);
		margin-bottom: 10px;
		@media screen and (max-width: 768px) {
			@include font-size(14);
			padding: 0px 5% 20px;
			margin-bottom: 30px;
		}
	}
	.program-col-new {
		padding-top: 32px;
		margin-bottom: 20px;
		@media screen and (max-width: 768px) {
			width: 100%;
			float: none;
			padding: 60px 0 0;
			margin-bottom: 40px;
		}
	}
	.program-col {
		width: 64.58%;
		float: left;
		padding-top: 32px;
		margin-bottom: 40px;
		@media screen and (max-width: 768px) {
			width: 100%;
			float: none;
			padding: 40px 0 0;
			margin-bottom: 55px;
		}
	}
	.tw-col {
		width: 33.4%;
		float: right;
		padding-top: 26px;
		margin-bottom: 40px;
		@media screen and (max-width: 768px) {
			width: 100%;
			float: none;
			padding: 0 0 15px;
		}
	}
	.onair-wrap {
		padding: 40px 0 20px;
		@media screen and (max-width: 768px) {
			padding: 40px 0 60px;
			.inner {
				padding: 0;
			}
			.ttl-wrap,
			.more-btn {
				padding: 0 15px;
			}
		}
	}
	.search-wrap {
		padding: 45px 0 32px;
		@media screen and (max-width: 768px) {
			padding: 40px 0 32px;
		}
	}
	.plan-wrap {
		padding: 40px 0 50px;
		@media screen and (max-width: 768px) {
			padding-bottom: 15px;
		}
	}
	.special-wrap {
		padding: 30px 0 43px;
		@media screen and (max-width: 768px) {
			padding-top: 38px;
			.top-ttl {
				margin-bottom: 30px;
			}
		}
	}
	.ttl-wrap {
		.top-ttl,
		.more-btn {
			display: inline-block;
			vertical-align: middle;
		}
		.more-btn {
			margin-bottom: 25px;
			margin-left: 30px;
			@media screen and (max-width: 768px) {
				display: none;
			}
		}
		@media screen and (max-width: 768px) {
			&.block-sp {
				display: block !important;
				.more-btn {
					display: block;
					margin-left: 0;
					a {
						display: block !important;
						padding: 9px;
					}
				}
			}
		}
	}
}

.contact-wrap {
	padding: 60px 0 35px;
	@media screen and (max-width: 768px) {
		padding: 30px 0 30px;
	}
	.contact-inner {
		padding: 0 7.5%;
		@media screen and (max-width: 768px) {
			padding: 0;
		}
	}
	.freedial-box {
		border-top: 1px solid #999999;
		border-bottom: #999999 1px solid;
		text-align: center;
		padding: 40px 0 0;
		margin-bottom: 35px;
		@media screen and (max-width: 768px) {
			padding-top: 25px;
		}
	}
	.zidaigeki {
		margin-top: 40px;
		border-top: #999999 1px solid;
		padding: 10px 0 0;
		@media screen and (max-width: 768px) {
			padding-top: 24px;
			margin-top: 25px;
		}
		.channel-ttl {
			font-weight: bold;
			@include font-size(15);
			margin-right: 20px;
			display: inline-block;
			color: #000;
			@media screen and (max-width: 768px) {
				display: block;
				margin: 0 0 -8px;
			}
		}
		a.pc{
			display: inline-block;
		}
		a.sp {
			img {
				@media screen and (max-width: 768px) {
					max-width: 300px;
				}
				@media screen and (max-width: 350px) {
					max-width: 90%;
				}
			}
		}
		img {
			display: inline-block;
			padding-bottom: 15px;
			@media screen and (max-width: 768px) {
				padding-bottom: 30px;
				margin-top: 5px;
			}
		}
	}
	.campaign-ttl {
		text-align: center;
		@include font-size(22);
		margin-bottom: 15px;
		letter-spacing: -2px;
		font-weight: bold;
		@media screen and (max-width: 768px) {
			@include font-size(19);
			margin-bottom: 18px;
		}
	}
	.campaign-list {
		li {
			width: 23.53%;
			float: left;
			margin-left: 1.96%;
			margin-bottom: 20px;
			@media screen and (max-width: 768px) {
				width: 48%;
				margin: 0 4% 15px 0 !important;
				text-align: center;
				&:nth-of-type(2n) {
					margin-right: 0 !important;
				}
			}
			&:first-of-type {
				margin-left: 0;
			}
		}
	}
	.campaign-list2 {
		text-align: center;
		font-size: 0;
		margin-bottom: 40px;
		@media screen and (max-width: 768px) {
			margin-bottom: 0;
		}
		li {
			display: inline-block;
			margin: 0 0.98%;
			@media screen and (max-width: 768px) {
				display: block;
				float: left;
				width: 48%;
				margin: 0 4% 15px 0 !important;
				&:nth-of-type(2n) {
					margin-right: 0 !important;
				}
			}
		}
	}
	.copy-txt {
		color: #999999;
		line-height: 1.2;
		@media screen and (max-width: 768px) {
			padding-top: 20px;
			padding-bottom: 50px;
			color: #666666;
		}
	}
}

.genre-list-sp {
	@media screen and (max-width: 768px) {
		border-bottom: 1px solid #ccc;
		margin: 30px 0;
	}
}

@media(max-width: 768px) {
	.width100 {
		img {
			width: 100%;
		}
	}
	.rollover{
		img.sp {
			width: 100%;
		}
	}
}
.mb0 {
	margin-bottom: 0 !important;
}
#back-to-top {
	position: fixed;
	bottom: 20px;
	right: 20px;
	@include smooth-transition;
	opacity: 0;
	visibility: hidden;
	width: 44px;
	height: 44px;
	background: url(../images/common/pagetop.png) no-repeat;
	z-index: 1;
	@media screen and (max-width: 768px) {
		right: 18px;
		bottom: 15px;
		width: 45px;
		height: 45px;
	}
	&.is-display {
		opacity: 1;
		visibility: visible;
	}
	&.remove-fixed {
		bottom: 72px;
		@media screen and (max-width: 768px) {
			bottom: auto;
			position: absolute;
			top: -55px;
		}
	}
	&:hover {
		opacity: .7;
	}
}
