/* ====================================================
Font
==================================================== */
$font-gothic: "游ゴシック", "游ゴシック体", YuGothicMedium, YuGothic, "Yu Gothic", "ヒラギノ角ゴ ProN", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, "ＭＳ ゴシック", "MS Gothic", "HiraKakuProN-W3", "TakaoExゴシック", TakaoExGothic, "MotoyaLCedar", "Droid Sans Japanese", sans-serif;
$font-mincho: "游明朝", "游明朝体", YuMincho, "Yu Mincho","ヒラギノ明朝 ProN", "Hiragino Mincho ProN", "ＭＳ 明朝", "MS Mincho", "HiraMinProN-W3", "TakaoEx明朝", TakaoExMincho, "MotoyaLCedar", "Droid Sans Japanese", serif;
$font-hiramincho-w2: "ヒラギノ明朝 Pro-W2", "Hiragino Mincho Pro-W2", "HiraMinPro-W2", "ヒラギノ明朝 Pro W2", "Hiragino Mincho Pro W2", "HiraMinPro W2";
$font-hiramincho-w3: "ヒラギノ明朝 Pro-W3", "Hiragino Mincho Pro-W3", "HiraMinPro-W3", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro W3", "HiraMinPro W3";
$font-hiramincho-w6: "ヒラギノ明朝 Pro-W6", "Hiragino Mincho Pro-W6", "HiraMinPro-W6", "ヒラギノ明朝 Pro W6", "Hiragino Mincho Pro W6", "HiraMinPro W6";

@mixin font-size($sizeValue) {
	font-size: ($sizeValue) + px;
	font-size: ($sizeValue / 10) + rem;
}

@mixin font-size-em($sizeValue) {
	font-size: ($sizeValue / 16) + em;
	font-size: ($sizeValue) + px;
}

@mixin font-weight($value) {
	@if ($value == "normal") {
		font-weight: 400;
	} @else if ($value == "medium") {
		font-weight: 500;
	} @else if ($value == "bold") {
		font-weight: 700;
	} @else if ($value == "bolder") {
		font-weight: 900;
	}
}


/* ====================================================
color
==================================================== */
$black: #333;
$red: #a70000;


/* ====================================================
Position & transform
==================================================== */
@mixin v-align {
	top: 50%;
	-webkit-transform: translateY(-50%);
	   -moz-transform: translateY(-50%);
	    -ms-transform: translateY(-50%);
	     -o-transform: translateY(-50%);
	        transform: translateY(-50%);
}

@mixin v-align-none {
	top: auto;
	-webkit-transform: translateY(0%);
	-moz-transform: translateY(0%);
	-ms-transform: translateY(0%);
	-o-transform: translateY(0%);
	transform: translateY(0%);
}

@mixin h-align {
	left: 50%;
	-webkit-transform: translateX(-50%);
	   -moz-transform: translateX(-50%);
	    -ms-transform: translateX(-50%);
	     -o-transform: translateX(-50%);
	        transform: translateX(-50%);
}

@mixin v-h-align {
	left: 50%;
	top: 50%;
	-webkit-transform: translate(-50%, -50%);
	   -moz-transform: translate(-50%, -50%);
	    -ms-transform: translate(-50%, -50%);
	     -o-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);
}

@mixin smooth-transition {
	-webkit-backface-visibility: hidden;
	-webkit-transition: all .3s ease;
	   -moz-transition: all .3s ease;
	    -ms-transition: all .3s ease;
	     -o-transition: all .3s ease;
	        transition: all .3s ease;
}

/* ====================================================
Color
==================================================== */

/* ====================================================
Sizing
==================================================== */
// Font size from px to em
$base-size: 16px;
@function px-em($target, $context: $base-size) {
	@if $target == 0 {
		@return 0
	}
	@return $target / $context + 0em;
}

/* ====================================================
Misc
==================================================== */
@mixin no-select {
	 -khtml-user-select: none;
	-webkit-user-select: none;
	   -moz-user-select: none;
	     -o-user-select: none;
	        user-select: none;
}
