.list-guidebook{
	border: 2px solid #40552e;
	padding: 15px;
	background: white;
	p{
		margin-bottom: 10px;
		&:last-child{
			margin-bottom: 0;
		}
	}
	.detail-txt-guidebook{
		margin-bottom: 10px;
		p{
			float: right;
			width: 77%;
			padding-left: 30px;
			@include font-size(13);
			@media screen and (max-width: 768px) {
				width: 100%;
				float: none;
				padding-left: 0;
			}
			&.txt-center{
				width: 100%;
				padding-left: 0;
			}
		}
		.left-img{
			position: absolute;
			top: 37px;
			max-width: 23%;
			@media screen and (max-width: 768px) {
				position: static;
				display: block;
				margin: 0 auto 15px;
				max-width: 100%;
			}
		}
	}
}
.guidebookPointInner {
	padding: 20px;
	background: #f5eee4;
	border-radius: 5px;
}
.list-pupop{
	margin-top: 15px;
	.item{
		float: left;
		width: 32%;
		margin-right: 2%;
		position: relative;
		@media screen and (max-width: 768px) {
			width: 100%;
			margin-right: 0;
			margin-bottom: 15px;
		}
		&:nth-child(3n){
			margin-right: 0;
			margin-bottom: 0;
		}
		.sample{
			position: absolute;
			bottom: 10px;
			left: 10px;
			right: 10px;
			width: calc(100% - 20px);
		}
	}
}
.sample-click{
	position: absolute;
	bottom: 15px;
	right: 10px;
	z-index: 1;
}
.tokusyu{
	position: relative;
	background: white;
	border-radius: 4px;
	margin-top: 20px;
	border: 1px solid #ccc;
	padding: 15px;
	.right-img{
		float: right;
		@media screen and (max-width: 768px) {
			width: 100%;
			margin-top: 15px;
		}
	}
	.txt-tokusyu{
		position: absolute;
		left: 20px;
		@include v-align;
		max-width: 45%;
		@media screen and (max-width: 768px) {
			position: static;
			display: block;
			margin: auto;
			@include v-align-none;
			max-width: 100%;
		}
	}
}
.howtoBox{
	background: url(../images/guidebook/howto_bg_box.gif);
	clear: both;
	margin: 10px 0;
	padding: 7px;
	.howtoBox_in{
		background: white;
		padding: 15px;
		h2{
			position: relative;
			padding-left: 30px;
			@include font-size(18);
			border-bottom: 1px dotted #ccc;
			margin-bottom: 10px;
			padding-bottom: 5px;
			&:before{
				display: block;
				content: '';
				background: url(../images/common/icon_waka.gif) no-repeat center center;
				background-size: 100% 100%;
				width: 23px;
				height: 23px;
				position: absolute;
				top: 0;
				left: 0;
			}
		}
		ul{
			color: #C00;
			@include font-size(12);
		}
		dl{
			@include font-size(12);
			margin-bottom: 30px;
			dt{
				float: left;
			}
			dd{
				float: left;
				background: #f1f1f1;
				width: calc(100% - 21px);
				padding-left: 10px;
				padding-top: 2px;
				min-height: 21px;
				position: relative;
				&:after{
					content: "";
					width: 20px;
					height: 10px;
					background: url(../images/guidebook/guidebook_ico_arrow.gif) no-repeat;
					position: absolute;
					bottom: -20px;
					left: 0;
					right: 0;
					margin: auto;
				}
			}
			&:last-child{
				margin-bottom: 0;
				dd{
					&:after{
						display: none;
					}
				}
			}
		}
	}
}
.item-links-guidebook{
	padding: 15px;
	@media screen and (max-width: 768px) {
		padding: 15px 0;
	}
}
.call-box-guidebook{
	.item{
		float: left;
		width: 53%;
		@media screen and (max-width: 768px) {
			width: 100%;
			margin-bottom: 15px;
		}
		&:nth-child(2){
			width: 45%;
			float: right;
			@media screen and (max-width: 768px) {
				width: 100%;
				margin-bottom: 0px;
			}
			.mihonshi{
				margin: 0 auto;
				max-width: 536px;
				background-color: #fdf9ef;
				border: 3px #920b13 solid;
				font-weight: bold;
				@include font-size(18);
				text-align: center;
				border-radius: 5px;
				.ttl{
					margin-bottom: 10px;
					padding: 5px;
					background-color: #920b13;
					font-size: 15px !important;
					color: #fff;
					border-radius: 2px 2px 0 0;
				}
				a{
					img{
						max-width: 90%;
						display: block;
						margin: auto;
					}
				}
				small{
					display: block;
					@include font-size(10);
					margin: 5px 0;
					padding: 0 5px;
				}
			}
		}
		.call_number_area{
			.ttl_call_number {
				margin-bottom: 10px;
				padding: 5px;
				background-color: #84603a;
				font-size: 15px !important;
				color: #fff;
				-webkit-border-radius: 2px 2px 0 0;
				-moz-border-radius: 2px 2px 0 0;
				-ms-border-radius: 2px 2px 0 0;
				-o-border-radius: 2px 2px 0 0;
				border-radius: 2px 2px 0 0;
			}
			.tel_call_number {
				padding: 0 5px 15px;
				@include font-size(30);
				color: red;
				a{
					display: block;
					margin-bottom: 5px;
				}
			}
			.tel_call_number span {
				@include font-size(14);
				color: #000;
				display: block;
			}
		}
	}
}
@media (min-width: 769px){
	.call-number{
		pointer-events: none;
	}
}
.show-box{
	position: relative;
}
@media screen and (max-width: 768px) {
	#cboxLoadedContent img {
		width: 300px !important;
		height: auto !important;
	}
}
@media screen and (max-width: 340px) {
	#cboxLoadedContent img {
		width: 280px !important;
	}
}
.show-box-faq{
	.accordion02 {
		dl {
			dt,dd {
				background: #ffffff;
			}
		}
	}
}