.page-sitemap {
  @include font-size(14);
  &.index {
     #sitemapWrap {
      a {
        display: block;
        margin: 0.4em 0;
        padding-left: 13px;
        background: url(../images/common/arrow_green_9px.gif) 0 center no-repeat;
        color: #535353;
        text-decoration: none;
        &.greySmall {
          background: url(/images/common/arrow_lnav_off.gif) 0 center no-repeat;
          color: #000;
          display: block;
          font-size: 85%;
          margin: 0.5em 0 0 10%;
          padding-left: 13px;
        }
        &:hover {
          color: #bc0d17;
        }
      }
      
      .sitemapMargin1 {
        border-top: #CCC 1px solid;
        p {
          border-bottom: #CCC 1px solid;
          margin: 0 0 0 6%;
          &.sitemapNoborder {
            border-bottom: none;
          }  
        }
      }
      .sitemapMargin2 {
        border-top: #CCC 1px solid;
        p {
          border-bottom: #CCC 1px solid;
          margin: 0 0 0 40%;   
          &.sitemapNoborder {
            border-bottom: none;
          }   
         }
      }
    }
  }
}