.page-hosoInfo {
	#mainInner {
	    background: #efedd7 !important;
	}
}
table#hosoInfo{
	clear:both;
	width:100%;
	border-top: 1px solid #ccc;
	border-right: 1px solid #ccc;
	margin-bottom:3em!important;
}
table#hosoInfo th{
	background:url(../images/common/bg_green.gif) repeat;
	border-left: 1px solid #ccc;
	border-bottom: 1px solid #ccc;
	color:#fff;
	font-size:110%;
	font-weight:bold;
	padding:0.6em;
	text-align:left;
}
table#hosoInfo td{
	background:#fff;
	border-left: 1px solid #ccc;
	border-bottom: 1px solid #ccc;
	line-height:1.2em;
	padding:0.6em;
	a {
    	color: #000;
	    text-decoration: underline;
	    &:hover {
    	    color: #bc0d17;
	    }
	}
}
table#hosoInfo td strong{
	font-size:110%;
}

#hosoInfo p.lead{
	margin-bottom:1em!important;
	height:3em;/*IE6、7対策*/
	position:relative;/*IE6、7対策*/
}
.sidebar-hoso {
	ul {
		li {
			a {
				&:before {
					background-color: #855d26 !important;
				}
			}
		}
	}
}
