.page-daily {
	padding-top: 31px;
	.top-ttl {
		margin-bottom: 25px;
	}
}

.txt-center {
	text-align: center;
}

@media screen and (max-width: 768px) {
	.show-cal {
		display: none;
	}
	.pagination-item {
		border: none;
		li {
			&.item {
				// width: 50%;
			}
		}
	}
}

.calendars-item {
	.pagination-item {
		@media (min-width: 1025px) {
			display: none;
		}
	}
	.btn-grey {
		display: none;
	}
	@media screen and (max-width: 1024px) {
		max-width: 375px;
		margin: auto;
		.pagination-item {
			border: none;
			li {
				width: 50%;
			}
		}
	}
	@media screen and (max-width: 768px) {
		.btn-grey {
			display: inline-flex;
		}
		max-width: 100%;
	}
}

.show-calendars {
	margin-bottom: 40px;
	@media screen and (max-width: 768px) {
		margin-bottom: 20px;
	}
	.item {
		float: left;
		width: 30.4%;
		margin-right: 4.4%;
		@include smooth-transition;
		&:nth-child(3n) {
			margin-right: 0;
		}
		@media screen and (max-width: 1024px) {
			display: none;
			width: 100%;
			margin-right: 0;
			&.current {
				display: block;
			}
		}
	}
}

.table-calendars {
	width: 100%;
	@include font-size(16);
	tr {
		th, td {
			text-align: center;
			border: 1px solid #aaaaaa;
			line-height: 1;
			@media screen and (max-width: 768px) {
				@include font-size(15);
			}
			&:nth-child(6) {
				color: #7db4ff;
				a {
					color: #7db4ff;
				}
			}
			&:nth-child(7) {
				color: #e6410e;
				a {
					color: #e6410e;
				}
			}
		}
		&:nth-child(1) {
			th {
				padding: 7px 12px;
			}
		}
		th {
			background: #333333;
			color: #cccccc;
			padding: 5px 12px;
			@media screen and (max-width: 350px) {
				padding: 5px;
			}
			span {
				&.num {
					@include font-size(26);
					@media screen and (max-width: 768px) {
						@include font-size(24);
					}
				}
				&.month {
					@include font-size(22);
					@media screen and (max-width: 768px) {
						@include font-size(20);
					}
				}
			}
		}
		td {
			padding: 8px 12px;
			&.active, &:hover {
				background: #977c10;
				color: white;
				@include smooth-transition;
				a {
					color: white !important;
				}
			}
			&.active{
					color: white !important;
			}
			@media screen and (max-width: 350px) {
				padding: 5px;
			}
			&.gray-bg{
				&.active, &:hover {
					background: #f1f1f1;
					color: #333;
					@include smooth-transition;
				}
				a{
					&:hover{
						background: #f1f1f1 !important;
					}
				}
			}
		}
	}
	&.table-calendars-nohover {
		tr {
			th, td {
				&.active, &:hover {
					@include smooth-transition;
					&:nth-child(6) {
						color: #7db4ff;
					}
					&:nth-child(7) {
						color: #e6410e;
					}
				}
			}
			td{
				&:hover{
					background: none;
					color: #333;
				}
				&.active{
					&:hover{
						text-decoration: underline;
						background: #977c10;
					}
				}
			}
		}
	}
}

.pagination-item {
	border-top: 1px solid #aaaaaa;
	border-bottom: 1px solid #aaaaaa;
	&.border-top-none {
		border-top: none;
	}
	&.border-bottom-none {
		border-bottom: none;
		&.sp {
			margin-top: 10px;
		}
	}
	.item {
		width: 33.333%;
		float: left;
		text-align: center;
		@include font-size(28);
		min-height: 70px;
		line-height: 70px;
		position: relative;
		@media screen and (max-width: 768px) {
			@include font-size(24);
			line-height: 1.4;
			&:nth-child(2) {
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}
		&.prev-item {
			text-align: left;
			a {
				padding-left: 30px;
				@media screen and (max-width: 768px) {
					padding-left: 23px;
					left: 0;
				}
				@media screen and (max-width: 360px) {
					padding-right: 23px;
				}
				&:before {
					left: 0;
					background: url("../images/common/pagination-prev.png") no-repeat;
					@media screen and (max-width: 768px) {
						background-position: left;
					}
				}
				span:hover {
					text-decoration: underline;
				}
			}
		}
		&.next-item {
			text-align: right;
			a {
				padding-right: 30px;
				@media screen and (max-width: 768px) {
					padding-right: 23px;
					right: 0;
				}
				@media screen and (max-width: 360px) {
					padding-left: 23px;
				}
				&:before {
					right: 0;
					background: url("../images/common/pagination-next.png") no-repeat;
					@media screen and (max-width: 768px) {
						background-position: right;
					}
				}
				span:hover {
					text-decoration: underline;
				}
			}
		}
		a {
			@include font-size(16);
			display: block;
			@media screen and (max-width: 768px) {
				@include font-size(14);
				line-height: 1.4;
				position: absolute;
				@include v-align
			}
			&:before {
				content: "";
				width: 23px;
				height: 40px;
				position: absolute;
				@include v-align;
				@media screen and (max-width: 768px) {
					width: 17px;
					height: 30px;
				}
			}
		}
	}
}

.btn {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	border: 1px solid #ccc;
	color: #977c10;
	line-height: 1;
	padding-left: 8px;
	padding-right: 13px;
	height: 36px;
	border-radius: 4px;
	position: relative;
	text-align: center;
	@include font-size(16);
	@include smooth-transition;
	&:before, &:after {
		display: inline-block;
		content: "";
	}
	&:before {
		margin-right: 10px;
	}
	&.btn-block {
		width: 100%;
	}
	&.btn-grey {
		background: #333333;
		color: white;
		border: none;
		border-radius: 0;
		@media screen and (max-width: 768px) {
			@include font-size(17);
			padding: 19px 0;
		}
		&:before {
			position: absolute;
			left: 15px;
			width: 22px;
			height: 22px;
			background: url("../images/common/calendar_icon.png") no-repeat;
		}
		&:after {
			position: absolute;
			right: 20px;
			top: 50%;
			width: 12px;
			height: 8px;
			background: url("../images/common/dow_icon.png") no-repeat;
			transform: translateY(-50%);
		}
		&.active {
			&:after {
				background: url("../images/common/top_icon.png") no-repeat;
			}
		}
	}
	&.btn-blue {
		color: #00a9e9;
		border: 1px solid #00a9e9;
		font-weight: bold;
		padding: 9px 45px 9px 20px;
		border-radius: 50px;
		@include font-size(18);
		@media screen and (max-width: 768px) {
			@include font-size(16);
			width: 100%;
			padding: 9px 30px 9px 0px;
		}
		&:after {
			right: 30px;
			width: 12px;
			height: 11px;
			background: url(../images/common/btn_blue_dow.png) no-repeat;
			position: absolute;
			@include v-align;
			@media screen and (max-width: 768px) {
				right: 13px;
			}
		}
	}
	&.btn-pdf {
		&:before {
			left: 5px;
			width: 32px;
			height: 32px;
			background: url("../images/common/pdf_icon.png") no-repeat;
			@media screen and (max-width: 768px) {
				left: 10px;
				width: 27px;
				height: 28px;
				background-size: 100%;
			}
		}
	}
	&.btn-guide {
		&:before {
			left: 10px;
			width: 21px;
			height: 20px;
			background: url("../images/common/guide_icon.png") no-repeat;
		}
	}
	&.btn-print {
		&:before {
			left: 10px;
			width: 24px;
			height: 21px;
			background: url("../images/timetable/print-icon.png") no-repeat;
		}
	}
	&.btn-calendar {
		&:before {
			left: 10px;
			width: 22px;
			height: 19px;
			background: url("../images/timetable/calendar-icon.png") no-repeat;
		}
	}
	&:hover {
		opacity: 0.7;
	}
}

.filtered-item-file {
	.item {
		float: left;
		margin-right: 10px;
		&:last-child {
			margin-right: 0;
		}
		@media screen and (max-width: 768px) {
			width: 48%;
			margin-right: 0;
			margin-bottom: 15px;
			&:last-child {
				margin-bottom: 0;
			}
			&:nth-child(2n) {
				float: right;
			}
			.btn {
				display: flex;
			}
			&.item-lg-sp {
				width: 100%;
			}
		}
	}
}

.filtered-item-links {
	@include font-size(16);
	.item {
		float: left;
		margin-right: 15px;
		@media screen and (max-width: 768px) {
			width: 100%;
			padding-bottom: 12px;
			&:last-child {
				padding-bottom: 0;
			}
		}
		a {
			display: block;
			position: relative;
			padding-left: 20px;
			@include smooth-transition;
			@media screen and (max-width: 768px) {
				@include font-size(14);
			}
			&:before {
				content: "";
				width: 20px;
				height: 20px;
				background: url("../images/common/links_icon.png") no-repeat;
				position: absolute;
				left: 0;
				@include v-align;
			}
			&:hover {
				opacity: 0.7;
				text-decoration: underline;
			}
		}
		&:last-child {
			margin-right: 0;
		}
	}
	&.block{
		display: inline-block;
		float: left;
		width: 100%;
	}
}

.filtered-item {
	padding: 16px 0;
	.filtered-item-file {
		@media screen and (max-width: 768px) {
			width: 100%;
		}
	}
	.filtered-item-links {
		padding-top: 10px;
		@media screen and (max-width: 768px) {
			width: 100%;
			padding-top: 16px;
			&.sp {
				padding-top: 20px;
				padding-bottom: 5px;
			}
		}
		&.pull-left{
			float: left;
			width: 100%;
			@media screen and (max-width: 768px){
				margin-bottom: 10px;
			}
		}
	}
	&.filtered-item-fix {
		padding: 20px 0;
	}
}

.table-category {
	width: 100%;
	@media screen and (max-width: 375px) {
		@media screen and (min-width: 0 \0
		) {
			width: 35px;
		}
	}
	tr {
		th, td {
			border: 1px solid #aaaaaa;
			vertical-align: top;
			padding: 20px;
			@media screen and (max-width: 768px) {
				padding: 15px 12px;
			}
		}
		th {
			background: #fbf4d8;
			@include font-size(20);
			width: 90px;
			@media screen and (max-width: 768px) {
				width: 45px;
				padding: 20px 0px;
				@include font-size(15);
				text-align: center;
			}
		}
		td {
			//@media screen and (min-width:0\0) {
			//	@media screen and (max-width: 375px) {
			//		.img{
			//			width: 20%;
			//		}
			//		.txt{
			//			width: 80%;
			//		}
			//	}
			//}
		}
	}
	& + p {
		padding: 12px 0;
		@media screen and (max-width: 768px) {
			padding: 15px 0;
		}
	}
}

.category-item {
	overflow: hidden;
	@include font-size(16);
	@media screen and (max-width: 768px) {
		@include font-size(14);
	}
	.img {
		float: left;
		width: 26.5%;
		@media screen and (max-width: 768px) {
			width: 46%;
		}
	}
	.txt {
		float: left;
		width: 73.5%;
		padding-left: 20px;
		@media screen and (max-width: 768px) {
			width: 54%;
			padding-left: 10px;
		}
		.ttl {
			display: block;
			color: #a70000;
			background: #fbf4d8;
			padding: 5px 10px;
			margin-bottom: 10px;
			@media screen and (max-width: 768px) {
				@include font-size(12);
				padding: 5px 8px;
			}
			a:hover {
				text-decoration: underline;
			}
		}
		.heading {
			@include font-size(24);
			color: #a70000;
			margin-bottom: 12px;
			@media screen and (max-width: 768px) {
				@include font-size(15);
				margin-bottom: 0;
			}
		}
		.cast {
			display: inline-block;
			width: 100%;
			dt {
				display: inline-block;
				vertical-align: top;
				margin-right: 2px;
			}
			dd {
				display: inline-block;
				a {
					color: #333;
					&:hover {
						text-decoration: underline;
					}
				}
			}
		}
	}
}

.daily-note {
	margin: 10px 0;
	@include font-size(18);
	@media screen and (max-width: 768px) {
		margin-top: 30px;
		@include font-size(16);
	}
	&.note-small {
		margin: 0 0 30px;
		@include font-size(14);
		@media screen and (max-width: 768px) {
			@include font-size(13);
		}
		span {
			color: #977c10;
		}
	}
	a {
		&:hover {
			text-decoration: underline;
		}
	}
}

.mt-sp {
	margin-top: 40px;
}

.page-daily {
	.table-calendars {
		tr {
			th {
				background: #333333;
				color: #cccccc;
				padding: 5px 12px;
				@media screen and (max-width: 350px) {
					padding: 5px;
				}
			}
			td {
				a {
					color: #333;
					display: block;
					padding: 8px 12px;
					&.color-blue {
						color: #7db4ff;
					}
					&:hover {
						background: #977c10;
						color: white;
						@include smooth-transition;
					}
				}
				padding: 0;
				&.active {
					color: white;
				}
				@media screen and (max-width: 350px) {
					a {
						padding: 5px;
					}
				}
			}
			th, td {
				&:nth-child(6) {
					color: #7db4ff;
					a {
						color: #7db4ff;
					}
				}
				&:nth-child(7) {
					color: #e6410e;
					a {
						color: #e6410e;
					}
				}
			}
		}
	}
	.heading {
		a {
			&:hover {
				text-decoration: underline;
				@media screen and (max-width: 768px) {
					text-decoration: none;
				}
			}
		}
	}
}

.contact-wrap-item {
	padding-top: 60px;
	@media screen and (max-width: 768px) {
		padding-top: 45px;
	}
}
