.page-corp {
  &.policy {
    @include font-size(13);
    #policyWrap {
      line-height: 1.6;
      margin: 20px 15px;
      padding: 0 30px;
      @media screen and (max-width: 768px){
        padding: 0;
        margin: 0 0 20px 0;
      }
      .policy_link {
        color: #333;
        text-decoration: underline;
        &:hover {
          color: #bc0d17;
        }
      }
      .title {
        clear: both;
        padding: 1em;
        background: url(/images/common/bg_gradation_gray.jpg) repeat-x;
        border: none;
        border: 1px solid #ccc;
        font-weight: bold;
      }
      p {
        margin-bottom: 20px;
        margin-top: 15px;
      }      
      ul {
        li {
          font-weight: bold;
          list-style-type: none;
          margin-bottom: 10px;
          margin-top: 3px;
          &.top {
            margin-top: 10px;
          }
        }
        ul {
          li {
            list-style-type: none;
            font-weight: normal;
            padding-right: 1em;
            padding-bottom: 5px;
          }
        }
      }
    }
  }
}