.show-view {
	position: absolute;
	top: 100%;
	right: 0;
	max-width: 480px;
	background: white;
	z-index: 1;
	border: 2px solid #f2dc22;
	@include font-size(15);
	text-align: left;
	display: none;
	@media screen and (max-width: 768px) {
		border: none;
		width: 100%;
		max-width: 100%;
		top: calc(100% + 2px);
	}
	.item {
		border-bottom: 1px solid #ccc;
		position: relative;
		width: 100%;
		padding: 20px 30px 20px 20px;
		@media screen and (max-width: 768px) {
			padding: 12px 30px 12px 20px;
		}
		&:after {
			content: "";
			width: 8px;
			height: 14px;
			position: absolute;
			right: 11px;
			@include v-align;
			background: url("../images/common/right-red.png") no-repeat;
		}
		&.logo-item {
			padding: 26px 30px 26px 20px;
			img {
				float: left;
				width: 260px;
			}
			span {
				float: left;
				color: #333333;
				padding: 13px 0 0 10px;
				line-height: 1;
				@include font-size(16);
				@media screen and (max-width: 768px) {
					display: block;
					width: 100%;
					padding: 13px 0 0 0;
				}
			}
			@media screen and (max-width: 768px) {
				padding: 15px 30px 15px 20px;
			}
		}
		&.logo-list {
			padding: 0;
			&:after {
				display: none;
			}
			ul {
				li {
					float: left;
					width: 33.333%;
					border: 1px solid #ccc;
					border-left: none;
					border-bottom: none;
					@media screen and (max-width: 768px) {
						border-right: none;
						width: 100%;
						a {
							position: relative;
							display: block;
							&:after {
								content: "";
								width: 8px;
								height: 14px;
								position: absolute;
								right: 11px;
								@include v-align;
								background: url("../images/common/right-red.png") no-repeat;
							}
						}
					}
					&:nth-child(1), &:nth-child(2) {
						width: 50%;
						border-bottom: none;
						@media screen and (max-width: 768px) {
							img {
								max-width: 100%;
							}
							a {
								&:after {
									background: url("../images/common/right.png") no-repeat;
								}
							}
						}
					}
					&:nth-child(2), &:last-child {
						border-right: none;
					}
					img {
						width: 100%;
						@media screen and (max-width: 768px) {
							max-width: 150px;
						}
						@media screen and (max-width: 360px) {
							max-width: 140px;
						}
					}
				}
			}
		}
		&.tel-view {
			background: #f1f1f1;
			padding: 18px 20px 13px 20px;
			&:after {
				display: none;
			}
			@media screen and (max-width: 768px) {
				img {
					max-width: 150px;
				}
			}
			@media screen and (max-width: 350px) {
				padding: 12px 30px 12px 20px;
			}
			span {
				@include font-size(14);
				padding-left: 10px;
				@media screen and (max-width: 768px) {
					@include font-size(11);
				}
			}
			a {
				display: inline-block;
				color: #333333;
			}
			li {
				margin-bottom: 15px;
				@media screen and (max-width: 768px) {
					margin-bottom: 12px;
				}
				@media screen and (max-width: 350px) {
					margin-bottom: 0px;
				}
				&:last-child {
					margin-bottom: 0;
					a {
						position: relative;
						padding-right: 20px;
						&:after {
							content: "";
							width: 8px;
							height: 16px;
							position: absolute;
							right: 0;
							@include v-align;
							background: url("../images/common/right-red.png") no-repeat;
						}
					}
				}
			}
		}
		a.rollover {
			letter-spacing: -1.5px;
		}
	}
}
.sidebar-shicho {
	#lNav {
		ul {
			background: url(../images/common/lnav_line.gif) 0 0 no-repeat;
			margin: 0 10px;
			li {
				background: none;
				a {
					background: url(../images/common/lnav_menu_bg.gif) 0 0 no-repeat;
					border: #fff solid 1px;
				    margin-bottom: 1px;
				    &:before {
				    	display: none;
				    }
				}
				&.cur {
					a { 
				    	background-position: 0 bottom;
						border: #d4be64 solid 1px;
				    }
				}
				&:hover {
					a { 
				    	background-position: 0 bottom;
						border: #d4be64 solid 1px;
				    }
				}
				&.TopSpace {
					padding-top: 10px;
				}
			}
		}
	}
	
}

.header-top {
	position: relative;
}

.show-view {
	position: absolute;
	top: 100%;
	right: 0;
	max-width: 480px;
	background: white;
	z-index: 1;
	border: 2px solid #a70000;
	@include font-size(15);
	text-align: left;
	display: none;
	@media screen and (max-width: 768px) {
		border: none;
		width: 100%;
		max-width: 100%;
		top: calc(100% + 2px);
	}
	.item {
		border-bottom: 1px solid #ccc;
		position: relative;
		width: 100%;
		padding: 20px 30px 20px 20px;
		&:after {
			content: "";
			width: 8px;
			height: 14px;
			position: absolute;
			right: 11px;
			@include v-align;
			background: url("../images/common/right.png") no-repeat;
		}
		&.logo-item {
			padding: 26px 30px 26px 20px;
			img {
				float: left;
				width: 260px;
			}
			span {
				float: left;
				color: #333333;
				padding: 13px 0 0 10px;
				line-height: 1;
				@include font-size(16);
				@media screen and (max-width: 768px) {
					display: block;
					width: 100%;
					padding: 13px 0 0 0;
				}
			}
			@media screen and (max-width: 768px) {
				padding: 15px 30px 15px 20px;
			}
		}
		&.logo-list {
			padding: 0;
			&:after {
				display: none;
			}
			ul {
				li {
					float: left;
					width: 33.333%;
					border: 1px solid #ccc;
					border-left: none;
					border-bottom: none;
					@media screen and (max-width: 768px) {
						width: 100%;
						a {
							position: relative;
							display: block;
							&:after {
								content: "";
								width: 8px;
								height: 14px;
								position: absolute;
								right: 11px;
								@include v-align;
								background: url("../images/common/right.png") no-repeat;
							}
						}
					}
					&:nth-child(1), &:nth-child(2) {
						width: 50%;
						border-bottom: none;
						@media screen and (max-width: 768px) {
							img {
								max-width: 100%;
							}
							a {
								&:after {
									background: url("../images/common/right_white.png") no-repeat;
								}
							}
						}
					}
					&:nth-child(2), &:last-child {
						border-right: none;
					}
					img {
						width: 100%;
						@media screen and (max-width: 768px) {
							max-width: 190px;
						}
					}
				}
			}
		}
		&.tel-view {
			background: #f1f1f1;
			padding: 18px 20px 13px 20px;
			@media screen and (max-width: 768px) {
				padding: 16px 20px 19px 20px;
			}
			&:after {
				display: none;
			}
			@media screen and (max-width: 768px) {
				img {
					max-width: 150px;
				}
			}
			span {
				@include font-size(14);
				padding-left: 10px;
				@media screen and (max-width: 768px) {
					@include font-size(11);
				}
			}
			a {
				color: #333333;
			}
			li {
				margin-bottom: 15px;
				@media screen and (max-width: 768px) {
					margin-bottom: 12px;
				}
				&:last-child {
					margin-bottom: 0;
					a {
						position: relative;					padding-right: 20px;
						&:after {
							content: "";
							width: 8px;
							height: 16px;
							position: absolute;
							right: 0;
							@include v-align;
							background: url("../images/common/right.png") no-repeat;
						}
					}
				}
			}
		}
		a.rollover {
			letter-spacing: -1.5px;
		}
	}
}

.heading-page {
	padding: 50px 0 70px;
	background: url("../images/shicho/bg_heading_page.png") bottom repeat-x;
	@media screen and (max-width: 768px) {
		background: url("../images/shicho/bg_heading_page_sp.png") repeat-x;
		background-size: 100% 100%;
		.img_header_page {
			height: 46px;
		}
	}
}

.list-img-shicho {
	margin: 55px 0 15px;
	.item {
		float: left;
		@include smooth-transition;
		&:hover {
			opacity: 0.7;
		}
		img {
			width: 100%;
		}
	}
	&.col-4 {
		.item {
			width: 25%;
			@media screen and (max-width: 768px) {
				width: 50%;
			}
		}
	}
}

.fint-item {
	.item {
		float: left;
		border: 2px solid #9c512d;
		border-right: none;
		position: relative;
		@include smooth-transition;
		&:nth-child(4n) {
			border-right: 2px solid #9c512d;
		}
		&:hover {
			opacity: 0.7;
		}
	}
	&.col-4 {
		.item {
			width: 25%;
			@media screen and (max-width: 768px) {
				width: 50%;
				border-bottom: none;
				background: white;
				&:nth-child(2n) {
					border-right: 2px solid #9c512d;
				}
			}
		}
	}
	@media screen and (max-width: 768px) {
		border-bottom: 2px solid #9c512d;
	}
}

.channels {
	position: relative;
	margin-top: -25px;
	background: #fbf4d8;
	.inner {
		@media screen and (max-width: 768px) {
			padding: 0 10px;
		}
	}
	img {
		margin-top: -15px;
		@media screen and (max-width: 768px) {
			&.sp {
				display: inline-block !important;
				margin: -18px 0 20px;
			}
		}
	}
	&:after {
		position: absolute;
		content: "";
		width: 0;
		height: 0;
		top: 100%;
		right: 0;
		left: 0;
		margin: auto;
		border-left: solid 30px transparent;
		border-right: solid 30px transparent;
		border-top: solid 30px #fbf4d8;
	}
	&-bg {
		
		position: absolute;
		width: 100%;
		height: calc(100% - 20px);
		left: 0;
		bottom: 0;
		z-index: -1;
	}
}

.scroll-page {
	padding: 60px 0 0px;
	@media screen and (max-width: 768px) {
		padding: 50px 0 0px;
	}
}

.ttl-parent {
	@include font-size(18);
	@media screen and (max-width: 768px) {
		text-align: left;
		@include font-size(16);
	}
}
.banner_time_specia {
	width: 103%;
	max-width: 103%;
	margin-left: -3%;
}
.link-parent {
	a {
		@include font-size(16);
		position: relative;
		display: inline-block;
		padding-left: 17px;
		@media screen and (max-width: 768px) {
			@include font-size(14);
			text-align: left;
			padding-left: 20px;
		}
		&:before {
			content: "";
			width: 7px;
			height: 12px;
			background: url("../images/common/right.png") no-repeat;
			background-size: cover;
			position: absolute;
			left: 0;
			@include v-align;
		}
	}
}

.scroll-link-page {
	margin: 25px -10px;
	@media screen and (max-width: 768px) {
		margin: 25px -8px;
	}
	.item {
		float: left;
		padding: 0 10px;
		a {
			display: block;
			img {
				width: 100%;
			}
		}
	}
	&.col-5 {
		.item {
			width: 20%;
			@media screen and (max-width: 768px) {
				width: 50%;
				margin-bottom: 15px;
				padding: 0 8px;
				&:nth-child(1) {
					width: 100%;
				}
			}
		}
	}
}

.show-item {
	max-width: 980px;
	margin: auto;
	.txt {
		max-width: 820px;
		padding: 50px 0 75px;
		margin: auto;
		@include font-size(14);
		@media screen and (max-width: 768px) {
			padding: 40px 20px 50px;
		}
		img {
			margin-bottom: 15px;
		}
	}
	.btn-blue {
		margin: 30px 0 20px;
		@media screen and (max-width: 768px) {
			margin: 25px 0 15px;
			line-height: 1.4;
			display: block;
			height: auto;
		}
		&-02 {
			margin: 50px 0 20px;
			@media screen and (max-width: 768px) {
				margin: 25px 0 15px;
				&:after {
					top: auto;
					bottom: 20%;
				}
			}
		}
	}
	strong {
		display: block;
		@include font-size(18);
		padding: 27px 0 12px;
		@media screen and (max-width: 768px) {
			@include font-size(16);
		}
	}
}

.heading-item {
	margin-top: 95px;
	@media screen and (max-width: 768px) {
		margin-top: 75px;
		img {
			width: 100%;
			max-width: 320px;
			margin: auto;
		}
	}
	&#ttl02 {
		margin: 120px 0 45px;
		@media screen and (max-width: 768px) {
			margin: 80px 0 35px;
		}
	} 
	&#ttl05 {
		margin: 115px 0 45px;
		@media screen and (max-width: 768px) {
			margin: 80px 0 35px;
		}
	}
	&#ttl04 {
		margin: 120px 0 45px;
		@media screen and (max-width: 768px) {
			margin: 80px 0 35px;
		}
	}
	&#ttl03 {
		margin: 120px 0 45px;
		@media screen and (max-width: 768px) {
			margin: 80px 0 35px;
		}
	}
	&#ttl02 {
		img {
			@media screen and (max-width: 768px) {
				max-width: 227px;
			}
		}
	}
	&#ttl05 {
		padding-left: 50px;
		@media screen and (max-width: 768px) {
			padding-left: 0px;
			margin-top: 65px;
		}
	}
}

.heading-txt {
	background: #00a9e9;
	color: white;
	@include font-size(24);
	position: relative;
	font-weight: bold;
	padding: 8px 30px;
	span {
		font-style: oblique;
	}
	@media screen and (max-width: 768px) {
		@include font-size(21);
	}
	&:before {
		content: "";
		width: 6px;
		background: white;
		position: absolute;
		left: 8px;
		top: 0;
		bottom: 0;
		@media screen and (max-width: 768px) {
			width: 5px;
		}
	}
}

.show-links {
	margin: 50px -10px 80px;
	display: flex;
	@media screen and (max-width: 768px) {
		display: block;
		margin: 40px -5px 50px;
	}
	.item {
		float: left;
		width: 27%;
		padding: 0 10px;
		display: flex;
		align-items: flex-end;
		justify-content: flex-end;
		@media screen and (min-width:0\0) {
			img{
				width: 100%;
			}
		}
		@media screen and (max-width: 768px) {
			padding: 0 5px;
			width: 50%;
			display: block;
			margin-bottom: 10px;
			img {
				width: 100%;
			}
		}
		&:nth-child(4n) {
			width: 19%;
			text-align: right;
			@media screen and (max-width: 768px) {
				width: 50%;
			}
		}
	}
}

.regiter-new {
	border: 3px solid #00a9e9;
	padding: 30px;
	margin-bottom: 80px;
	@media screen and (max-width: 768px) {
		padding: 15px;
		margin-bottom: 60px;
	}
	li {
		float: left;
		width: 45%;
		position: relative;
		@include font-size(18);
		@media screen and (max-width: 768px) {
			width: 100%;
			text-align: center;
			@include font-size(16);
			img {
				margin: auto;
			}
		}
		&:nth-child(2n) {
			float: right;
			&:before {
				content: "";
				width: 22%;
				height: 107px;
				background: url(../images/common/right_blue.png) no-repeat center;
				position: absolute;
				right: 100%;
				@include v-align;
			}
		}
		.btn {
			@include font-size(18);
			background: #00a9e9;
			color: white;
			border-radius: 0;
			border: none;
			padding: 8px 13px;
			margin-top: 15px;
			max-width: 400px;
			font-weight: bold;
			float: left;
			@media screen and (max-width: 768px) {
				max-width: 200px;
				@include font-size(16);
				padding: 6px 13px;
				margin-bottom: 20px;
				float: none;
				& + a img {
					margin-bottom: 15px;
				}
			}
		}
		img {
			display: block;
		}
		span {
			@media screen and (max-width: 768px) {
				display: block;
			}
		}
		&.pc {
			img {
				margin-bottom: 15px;
			}
			span {
				padding-left: 12px;
			}
		}
	}
}

.tab-menu {
	border-bottom: 3px solid #00a9e9;
	margin-top: 50px;
	@media screen and (max-width: 768px) {
		border-bottom: none;
		margin-bottom: 25px;
	}
	.item {
		float: left;
		background: #87d2f4;
		color: white;
		@include font-size(22);
		font-weight: bold;
		cursor: pointer;
		padding: 7px;
		&.active {
			background: #00a9e9;
		}
		&:nth-child(1) {
			font-style: oblique;
		}
	}
	&.col-3 {
		.item {
			width: 32.4%;
			margin-right: 1.4%;
			&:nth-child(3n) {
				margin-right: 0;
				float: right;
			}
			@media screen and (max-width: 768px) {
				width: 100%;
				margin-right: 0;
				@include font-size(19);
				position: relative;
				&.active {
					&:after {
						content: "";
						width: 13px;
						height: 8px;
						background: url("../images/common/dow_icon.png") no-repeat;
						position: absolute;
						right: 20px;
						@include v-align;
					}
				}
			}
		}
	}
}

.tab-content {
	display: none;
	@include font-size(18);
	border: 1px solid #00a9e9;
	border-top: none;
	padding: 25px 40px 40px;
	@media screen and (max-width: 768px) {
		border: none;
		padding: 0;
	}
	.mt50 {
		margin-top: 50px;
	}
	.banner_02 {
		display: block;
		padding-top: 40px;
	}
	.img_txt_tab_01 {
		max-width: 100.8%;
		width: 100.8%;
		margin-left: -.8%;
	}
	.txt-center {
		a {
			display: inline-block;
		}
	}
	.tv {
		.list-item {
			.item {
				width: 50%;
				img{
					width: 100%;
				}
				&.tel-item {
					width: 47.55%;
					.show {
						padding: 24px 20px;
						a {
							img {
								float: left;
							}
						}
					}
				}
			}
		}
	}
}

.tab-content.active {
	display: block;
}

.list-item {
	margin-top: 45px;
	.item {
		float: left;
		width: 46%;
		@media screen and (max-width: 768px) {
			width: 100%;
			margin-bottom: 15px;
		}
		@include font-size(16);
		&:nth-child(2n) {
			float: right;
		}
		&.tel-item {
			width: 51.55%;
			@media screen and (max-width: 768px) {
				width: 100%;
			}
			.show {
				padding: 20px;
				line-height: 1;
				.ttl {
					font-weight: bold;
					display: block;
					@include font-size(21);
					@media screen and (max-width: 768px) {
						@include font-size(18);
					}
				}
			}
		}
	}
}

.tv {
	.list-item {
		margin-top: 45px;
		.item {
			&.tel-item {
				.show {
					border: 3px solid #00a2e6;
					.ttl {
						color: #00a2e6;
						margin-bottom: 15px;
					}
					img {
						margin-bottom: 15px;
					}
					.txt-item {
						float: left;
						@media screen and (max-width: 990px) {
							width: 100%;
						}
					}
					.oder-list {
						float: right;
						background: #00a2e6;
						color: white;
						width: 70px;
						height: 70px;
						padding: 8px 2px 0;
						margin-top: -2px;
						font-size: 13px;
						line-height: 1.4;
						font-weight: bold;
						@media screen and (max-width: 990px) {
							width: 100%;
							margin-bottom: 10px;
							height: auto;
							@include font-size(16);
							padding: 3px 10px;
						}
						@media screen and (max-width: 768px) {
							@include font-size(16);
							background: none;
							padding: 0;
							margin-bottom: 20px;
							margin-top: 5px;
							span {
								background: #00a2e6;
								padding: 3px 15px;
							}
							img {
								margin-bottom: 20px;
							}
						}
						& + p {
							float: left;
							width: 100%;
							@include font-size(18);
							font-weight: 500;
						}
					}
					.tv-reception {
						@include font-size(18);
						@media screen and (max-width: 768px) {
							@include font-size(16);
						}
					}
				}
			}
		}
	}
}

.j-com {
	margin-top: 45px;
	.list-item {
		margin-top: 30px;
		.item {
			&.tel-item {
				.show {
					border: 3px solid #e83316;
					.ttl {
						color: #e83316;
						margin-bottom: 20px;
					}
					img {
						margin-bottom: 23px;
					}
					p {
						margin-bottom: 13px;
						&:last-child {
							margin-bottom: 0;
							@include font-size(18);
							@media screen and (max-width: 768px) {
								@include font-size(16);
							}
						}
					}
				}
			}
		}
	}
}

.au {
	margin-bottom: 55px;
	@media screen and (max-width: 768px) {
		margin-bottom: 25px;
	}
	.list-item {
		margin: 45px 0 10px;
		.item {
			&.tel-item {
				.show {
					border: 3px solid #ed6e01;
					.ttl {
						color: #ed6e01;
						margin-bottom: 20px;
						@media screen and (max-width: 768px) {
							line-height: 1.4;
							margin-bottom: 15px;
						}
					}
					img {
						margin-bottom: 18px;
					}
					p {
						@include font-size(18);
						@media screen and (max-width: 768px) {
							@include font-size(16);
							span {
								display: block;
								margin-bottom: 15px;
							}
						}
					}
				}
			}
		}
	}
	.samll {
		margin-top: 10px;
		@include font-size(14);
	}
}

.accordion {
	margin-top: 40px;
	dl {
		margin-bottom: 20px;
		&:last-child {
			margin-bottom: 0;
		}
		dt {
			font-weight: bold;
			text-align: center;
			padding: 7px;
			color: white;
			background: #87d2f4;
			position: relative;
			@include font-size(19);
			&.active {
				background: #00a9e9;
			}
			&:after {
				content: "";
				width: 13px;
				height: 8px;
				background: url(../images/common/top_icon.png) no-repeat;
				position: absolute;
				right: 20px;
				@include v-align;
			}
			&.active {
				&:after {
					background: url(../images/common/dow_icon.png) no-repeat;
				}
			}
		}
		dd {
			display: none;
			padding: 25px 0 30px;
			@media screen and (max-width: 768px) {
				.list-item .item {
					.mt40 {
						margin-top: 5px;
					}
					p {
						font-weight: 500;
					}
				}
			}
		}
		&:nth-child(1) {
			dt {
				font-style: oblique;
			}
		}
	}
}

.japanmap {
	margin-top: 45px;
	.img {
		float: left;
		width: 60%;
	}
	.area-map {
		float: right;
		width: 40%;
		@media screen and (max-width: 768px) {
			width: 100%;
		}
		li {
			margin-bottom: 2px;
			&:last-child {
				margin-bottom: 0;
			}
			a {
				display: block;
				@include smooth-transition;
			}
			&:hover {
				a {
					&.hv {
						&-01 {
							background: rgba(55, 134, 197, 0.29);
						}
						&-02 {
							background: rgba(135, 203, 249, 0.34);
						}
						&-03 {
							background: rgba(47, 162, 47, 0.38);
						}
						&-04 {
							background: rgba(184, 199, 179, 0.32);
						}
						&-05 {
							background: rgba(187, 119, 223, 0.29);
						}
						&-06 {
							background: rgba(22, 149, 147, 0.34);
						}
						&-07 {
							background: rgba(181, 226, 158, 0.41);
						}
						&-08 {
							background: rgba(249, 202, 139, 0.48);
						}
						&-09 {
							background: rgba(242, 148, 38, 0.43);
						}
						&-10 {
							background: rgba(232, 64, 37, 0.31);
						}
					}
				}
			}
		}
	}
}

.txt-right {
	text-align: right;
}
.txt-italic {
	font-style: italic;
}
.mt40 {
	margin-top: 40px;
}

.page-shicho {
	.regiter-new {
		.btn {
			&:hover {
				opacity: 1;
			}
		}
	}
	a {
		&.tel-event {
			pointer-events: none;
			display: block;
		}
	}
	.tel-item {
		a {
			&.tel-event {
				@media screen and (max-width: 768px) {
					pointer-events: inherit;
				}
			}
		}
	}
}
.btn-nohover{
	&:hover{
		opacity:1 !important;
	}
}
.mb20 {
	margin-bottom: 20px !important;
}

.mb10 {
	margin-bottom: 10px !important;
}
