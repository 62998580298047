.s-window{
	padding-right: 50px;
	&::-webkit-input-placeholder {
		color: #999999;
	}
	&:-moz-placeholder {
		color: #999999;
	}
	&::-moz-placeholder {
		color: #999999;
	}
	&:-ms-input-placeholder {
		color: #999999;
	}

	&::-ms-clear {
		display: none;
	}
	&::-webkit-contacts-auto-fill-button {
		visibility: hidden;
		display: none !important;
		pointer-events: none;
		position: absolute;
		right: 0;
	}
}
.news-list{
	li{
		.ttl{
			@media screen and (max-width : 1110px) {
				margin-top: 10px;
			}
		}
	}
}
.onair-list{
	width: 2480px;
}
.slick-slide{
	img{
		@media screen and (min-width:0\0) {
			width: 100%;
		}
	}
}

.flex-ttl{
	display: flex !important;
	align-items: center;
	.more-btn{

	}
}
.top-ttl{
	&.flex-ttl{
		position: relative;
		padding-left: 63px;
		letter-spacing: 1.8px;
		&:before{
			position: absolute;
			top: 50%;
			left: 0;
			-webkit-transform: translate(0, -50%);
			-ms-transform: translate(0, -50%);
			transform: translate(0, -50%);
		}
		&.flex-ttl-fix{
			padding-left: 67px;
			padding-top: 5px;
			@media(max-width: 768px){
				padding-left: 64px;
			}
		}
	}
}
@media screen and (max-width : 768px){
	.main-slider{
		.slick-next, .slick-prev{
			top: calc(50% - 110px);
			-webkit-transform: translate(0, -50%);
			-ms-transform: translate(0, -50%);
			transform: translate(0, -50%);
		}
	}
	.onair-list {
		.owl-nav{
			.owl-prev,.owl-next{
				top: calc(50% - 40px);
				width: 27px;
				height: 27px;
			}
		}
	}
	.theme-slide{
		.owl-nav{
			.owl-prev,.owl-next{
				top: calc(50% - 25px);
			}
		}
	}
	.tw-frame{
		border: none;
		text-align: center;
		iframe{
			border: 1px solid #ccc !important;
			border-radius: 3px !important;
			margin: auto;
		}
	}
	.program-box{
		.box {
			.img {
				img{
					@media screen and (min-width:0\0) {
						position: static;
					}
				}
			}
		}
	}
}
.ttl-plan-list{
	position: relative;
	@include font-size(20);
	color: #977c10;
	margin-bottom: 20px;
	@media screen and (max-width : 768px){
		@include font-size(19);
	}
	&:after{
		content: '';
		position: absolute;
		left: 0;
		right: 0;
		top: 50%;
		-webkit-transform: translate(0, -50%);
		-ms-transform: translate(0, -50%);
		transform: translate(0, -50%);
		height: 1px;
		background: #ccc;
	}
	span{
		display: inline-block;
		background: white;
		position: relative;
		padding: 0 15px;
		margin-left: 25px;
		line-height: 1;
		z-index: 1;
		border-left: 2px solid #ccc;
		border-right: 2px solid #ccc;
		font-weight: bold;
		@media screen and (max-width : 768px){
			margin-left: 15px;
			padding: 0 10px;
		}
	}
}
.ttl-plan-list-02{
	margin-top: 20px;
	@media screen and (max-width : 768px){
		margin-top: 50px;
	}
}
.show-header{
	position: absolute;
	top: 100%;
	left: 0;
	right: 0;
	background: white;
	padding: 25px 30px 5px;
	text-align: center;
	border: 2px solid #977c10;
	display: none;
	img{
		display: block;
		margin: auto;
		margin-bottom: 20px;
	}
}