.parent-list {
	margin-bottom: 30px;
	@media screen and (max-width: 768px) {
		margin-bottom: 20px;
	}
	.item {
		float: left;
		background: #f1f1f1;
		.txt {
			@include font-size(16);
			padding: 10px 15px 15px;
			color: #333333;
			font-weight: 500;
			@include smooth-transition;
			min-height: 96px;
			@media screen and (max-width: 768px) {
				@include font-size(13);
				min-height: 10px;
			}
			@media screen and (max-width: 350px) {
				padding: 5px 10px 5px;
			}
			small {
				font-weight: normal;
				display: block;
				@include font-size(12);
				line-height: 1.8;
				@media screen and (max-width: 768px) {
					@include font-size(10);
				}
			}
		}
		&:hover{
			.img{
				opacity: 0.7;
				@include smooth-transition;
			}
			.txt{
				color: #977c10;
				text-decoration: underline;
			}
		}
	}
	&.col-4 {
		.item {
			display: block;
			float: left;
			width: 23.75%;
			margin-right: 1.6%;
			margin-bottom: 1.6%;
			&:nth-child(4n) {
				margin-right: 0;
			}
			@media screen and (max-width: 768px) {
				width: 100%;
				overflow: hidden;
				margin-bottom: 15px;
				&:last-child{
					margin-bottom: 0;
				}
				.img {
					float: left;
					width: 40%;
				}
				.txt {
					float: right;
					width: 60%;
				}
			}
			@media screen and (max-width: 360px) {
				.txt {
					small {
						@include font-size(10)
					}
				}
			}
		}
	}
}

.accordion-sp {
	margin-top: 40px;
	.parent-item {
		.parent-list {
			@media (min-width: 769px) {
				display: block !important;
			}
		}
	}
}

.options-02 {
	display: flex;
	@media screen and (max-width: 768px) {
		flex-wrap: wrap;
		margin-bottom: -10px;
		justify-content: space-between;
	}
	li {
		display: flex;
		flex-grow: 1;
		float: left;
		width: 10%;
		margin-right: 10px;
		&:last-of-type {
			margin-right: 0;
		}
		@media screen and (max-width: 768px) {
			width: 32.5%;
			margin-bottom: 10px;
			flex-grow: 0;
			justify-content: space-between;
			margin-right: 0;
		}
		a {
			display: flex;
			justify-content: center;
			align-items: center;
			text-align: center;
			width: 100%;
			height: 100%;
			padding: 8px 5px;
			border-radius: 5px;
			background-color: #fff;
			color: #977c10;
			@include font-size(16);
			@include smooth-transition;
			@media screen and (max-width: 768px) {
				@include font-size(14);
			}
			&:hover {
				background-color: #977c10;
				color: white;
			}
			&:before {
				display: block;
				content: '';
				width: 0;
				height: 0;
				background-color: transparent;
			}
		}
	}
}

@media screen and (max-width: 768px) {
	.accordion-sp {
		.parent-item {
			.article-sub-ttl {
				position: relative;
				&:after {
					content: "";
					width: 15px;
					height: 11px;
					background: url(../images/program/dow.png) no-repeat;
					background-size: 100%;
					position: absolute;
					right: 15px;
					@include v-align;
				}
				&.active {
					&:after {
						background: url(../images/program/top.png) no-repeat;
						background-size: 100%;
					}
				}
			}
			.parent-list {
				display: none;
			}
			&.active {
				.parent-list {
					display: block;
				}
			}
		}
	}
}
