.iconInfoAWrap {
	background: #fff;
	border-width: 0 1px 0 1px;
	border-color: #ccc;
	border-style: solid;
	padding: 10px;
	&.no-border {
		border: none;
	}
	.guideFoot {
		border-top: 1px dotted #ccc;
		padding: 1.2em 0 0 0;
		margin-bottom: 1.25em;
		ul {
			display: flex;
			justify-content: space-around;
			@media screen and (max-width: 768px) {
				flex-wrap: wrap;
			}
			li {
				color: #000;
				@include font-size(14);
				@media screen and (max-width: 768px) {
					width: 100%;
				}
				&:before {
					display: inline-block;
					content: '';
					background: url(../images/shicho/houjin/icon-arrow.png) no-repeat center center;
					background-size: 100% 100%;
					width: 10px;
					height: 10px;
					border-radius: 2px;
				}
				a {
					color: #000;
					@include font-size(14);
					&:hover {
						text-decoration: underline;
					}
				}
			}
		}
		p {
			@include font-size(13);
			@media screen and (max-width: 768px) {
				text-align: left !important;
			}
		}
	}
}

#progTab {
	display: flex;
	align-items: center;
	border: 1px solid #d2d2d2;
	@media screen and (max-width: 768px) {
		flex-wrap: wrap;
	}
	.tab-label {
		display: block;
		font-weight: bold;
		@include font-size(14);
		padding: 10px 20px;
		white-space: nowrap;
		@media screen and (max-width: 768px) {
			width: 100%;
		}
	}
	.tabContent {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		@media screen and (max-width: 768px) {
			padding: 0 20px;
			flex-wrap: wrap;
		}
		.tabSort {
			display: flex;
			position: relative;
			bottom: -1px;
			li {
				border: 1px solid #d2d2d2;
				background: #ffffff;
				background: -moz-linear-gradient(top, #ffffff 0%, #f1f1f1 100%);
				background: -webkit-linear-gradient(top, #ffffff 0%, #f1f1f1 100%);
				background: linear-gradient(to bottom, #ffffff 0%, #f1f1f1 100%);
				filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#f1f1f1', GradientType=0);
				border-top-width: 2px;
				display: flex;
				justify-content: center;
				align-items: center;
				@include font-size(14);
				@include smooth-transition;
				&:before {
					display: block;
					content: '';
					width: 0;
					height: 0;
					background-color: transparent;
				}
				a {
					padding: 10px 20px;
					font-weight: bold;
					color: #000;
				}
				&.active, &:hover {
					border-top-color: #c1222c;
					border-bottom-color: white;
					@include smooth-transition;
					background: #f7f7f7;
					background: -moz-linear-gradient(top, #f7f7f7 0%, #ffffff 100%);
					background: -webkit-linear-gradient(top, #f7f7f7 0%, #ffffff 100%);
					background: linear-gradient(to bottom, #f7f7f7 0%, #ffffff 100%);
					filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f7f7f7', endColorstr='#ffffff', GradientType=0);
					a {
						color: #c1222c;
					}
				}
			}
		}
		.tabMonth {
			display: flex;
			li {
				border: 1px solid #CCCCCC;
				display: flex;
				align-items: center;
				justify-content: center;
				text-align: center;
				background: #ffffff;
				background: -moz-linear-gradient(top, #ffffff 0%, #f1f1f1 100%);
				background: -webkit-linear-gradient(top, #ffffff 0%, #f1f1f1 100%);
				background: linear-gradient(to bottom, #ffffff 0%, #f1f1f1 100%);
				filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#f1f1f1', GradientType=0);
				@include smooth-transition;
				&:before {
					display: block;
					content: '';
					width: 0;
					height: 0;
					background-color: transparent;
				}
				a {
					display: block;
					padding: 5px 10px;
					color: #000;
					text-decoration: underline;
					font-weight: bold;
					@include font-size(14);
					@include smooth-transition;
				}
				&.active, &:hover {
					background: #430d0a;
					@include smooth-transition;
					a {
						text-decoration: none;
						color: white;
						@include smooth-transition;
					}
				}
			}
		}
	}
}

.programTitle {
	background: #eaeaea;
	background: -moz-linear-gradient(top, #eaeaea 0%, #ffffff 28%);
	background: -webkit-linear-gradient(top, #eaeaea 0%, #ffffff 28%);
	background: linear-gradient(to bottom, #eaeaea 0%, #ffffff 28%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#eaeaea', endColorstr='#ffffff', GradientType=0);
	clear: both;
	border: #d6d6d6 1px solid;
	border-top: none;
	padding: 5px 10px 3px 3px;
	@include font-size(18);
	margin-bottom: 10px;
	div {
		padding-left: 10px;
		border-left: #bb0d16 3px solid;
	}
}

.heightLineParent {
	overflow: hidden;
	.progBox {
		border: 1px solid #ccc;
		float: left;
		width: 49.5%;
		margin-right: 1%;
		margin-bottom: 1%;
		&:nth-of-type(2n) {
			margin-right: 0;
		}
		@media screen and (max-width: 768px) {
			width: 100%;
			margin-right: 0;
		}
		.progBoxIcon {
			background: #f1f1f1;
			margin: 1px 1px 8px 1px;
			text-align: right;
		}
		.progBoxInfo2 {
			margin: 0 7px 7px 7px;
			.progBoxTit {
				text-align: justify;
				a {
					font-weight: bold;
					color: #69C;
					text-decoration: underline;
					@include font-size(14);
					@include smooth-transition;
					&:hover {
						color: #bb0d16;
						@include smooth-transition;
					}
				}
			}
			.progBoxDate {
				@include font-size(12);
			}
			.progBoxStaff {
				background: #f1f1f1;
				@include font-size(12);
				line-height: 140%;
				margin: 5px 0 0 0;
				padding: 5px;
			}
		}
	}
}

#yearList {
	clear: both;
	border-top: 1px dotted #ccc;
	border-bottom: 1px dotted #ccc;
	letter-spacing: .03em;
	margin-bottom: 30px;
	padding: .6em 0 .6em;
	@include font-size(14);
	a {
		display: inline-block;
		color: #000;
		margin: 0 2px;
		@include font-size(14);
	}
}

.pc {
	@media screen and (max-width: 768px) {
		display: none !important;
	}
}

.sp {
	@media screen and (min-width: 769px) {
		display: none !important;
	}
}

#page-genre {
	padding-top: 32px;
	.top-ttl {
		@include font-size(34);
		@media screen and (min-width: 769px) {
			margin-bottom: 10px;
		}
		@media screen and (max-width: 768px) {
			@include font-size(30);
		}
	}
}

.search-module {
	padding: 25px 0;
	position: relative;
	&:after {
		display: block;
		content: '';
		width: 0;
		height: 0;
		border: 22px solid transparent;
		border-top: 23px solid #f1f1f1;
		position: absolute;
		top: 100%;
		@include h-align;
	}
	.navi-links {
		display: flex;
		border-bottom: 1px solid #666666;
		margin-bottom: 22px;
		@media screen and (max-width: 768px) {
			margin-bottom: 20px;
			justify-content: space-between;
		}
		li {
			display: flex;
			width: 22%;
			justify-content: center;
			text-align: center;
			align-items: center;
			margin-right: 10px;
			background-color: #fff;
			position: relative;
			top: 1px;
			border: 1px solid transparent;
			border-top-right-radius: 5px;
			border-top-left-radius: 5px;
			border-bottom: 1px solid #666666;
			@include smooth-transition;
			@media screen and (max-width: 768px) {
				margin-right: 0;
				width: 32.5%;
			}
			&:before {
				display: block;
				content: '';
				width: 0;
				height: 0;
				background-color: transparent;
			}
			a {
				display: block;
				width: 100%;
				text-align: center;
				padding: 12px;
				color: #977c10;
				@include font-size(22);
				font-weight: bold;
				@media screen and (max-width: 768px) {
					line-height: 1.2;
					@include font-size(14);
					padding: 12px 3px;
				}
				small{
					@include font-size(16);
					@media screen and (max-width: 768px) {
						@include font-size(11);
					}
				}
			}
			&.active {
				background-color: #f1f1f1;
				border: 1px solid #666666;
				border-bottom-color: #f1f1f1;
				@include smooth-transition;
				&:after {
					display: block;
					content: '';
					width: 100%;
					height: 4px;
					background-color: #977c10;
					position: absolute;
					top: 0;
					left: 0;
				}
				a {
					color: #333333;
				}
			}
			@media (min-width: 769px){
				&:hover {
					background-color: #f1f1f1;
					border: 1px solid #666666;
					border-bottom-color: #f1f1f1;
					@include smooth-transition;
					&:after {
						display: block;
						content: '';
						width: 100%;
						height: 4px;
						background-color: #977c10;
						position: absolute;
						top: 0;
						left: 0;
					}
					a {
						color: #333333;
					}
				}
			}

			&.active {
				a {
					&:before {
						display: inline-block;
						content: '';
						width: 8px;
						height: 8px;
						border-right: 2px solid #333333;
						border-bottom: 2px solid #333333;
						transform: rotate(45deg);
						position: relative;
						bottom: 4px;
						margin-right: 10px;
						@media screen and (max-width: 768px) {
							width: 5px;
							height: 5px;
							position: absolute;
							left: 50%;
							transform: rotate(45deg) translateX(-50%);
							bottom: 0;
						}
					}
				}
			}
		}
	}
	.navi-months {
		display: flex;
		margin-bottom: 16px;
		@media screen and (max-width: 768px) {
			justify-content: space-between;
			margin-bottom: 20px;
		}
		li {
			margin-right: 10px;
			@media screen and (max-width: 768px) {
				width: 32.5%;
				margin: 0;
			}
			a {
				display: block;
				border-radius: 5px;
				background-color: #fff;
				color: #977c10;
				padding: 8px 68px;
				@include font-size(16);
				@include smooth-transition;
				@media screen and (max-width: 768px) {
					padding: 10px;
					text-align: center;
					@include font-size(14);
				}
			}
			&.active, &:hover {
				a {
					background-color: #977c10;
					color: white;
					@include smooth-transition;
				}
			}
		}
	}
}

.navi-genres {
	height: auto;
	padding: unset;
}

.select {
	display: block;
	height: 45px;
	cursor: pointer;
	position: relative;
	font: normal 11px/22px Arial, Sans-Serif;
	color: black;
	@media screen and (min-width: 769px) {
		height: auto;
		select {
			display: none;
		}
	}
}

.styledSelect {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: #333333;
	padding: 13px 10px;
	text-align: center;
	font-weight: bold;
	color: white;
	@include font-size(14);
	@include smooth-transition;
	@media screen and (min-width: 769px) {
		display: none;
	}
	&:after {
		content: "";
		width: 8px;
		height: 8px;
		border-bottom: 2px solid white;
		border-right: 2px solid white;
		transform: rotate(45deg) translateY(-50%);
		position: absolute;
		right: 20px;
		top: 42%;
	}
	&:active, &.active, &:hover {
		background-color: #444444;
		@include smooth-transition;
	}
	&:active, &.active {
		&:after {
			transform: rotate(225deg) translateY(-50%);
			right: 13px;
			top: 37%;
		}
	}
}

.options {
	display: none;
	position: absolute;
	top: 100%;
	right: 0;
	left: 0;
	z-index: 999;
	margin: 0 0;
	padding: 0 0;
	list-style: none;
	border: 1px solid #ccc;
	background-color: white;
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
	li {
		padding: 8px;
		margin: 0;
		@include font-size(14);
		@include smooth-transition;
		&:hover {
			background-color: #f1f1f1;
			@include smooth-transition;
			color: #977c10;
		}
		@media(max-width: 768px){
			padding: 0;
		}
		a{
			@media(max-width: 768px){
				display: inline-block;
				width: 100%;
				padding: 8px;
			}
		}
	}
	@media screen and (min-width: 769px) {
		position: relative;
		display: block !important;
		border: none;
		box-shadow: none;
		background-color: transparent;
		margin-bottom: -10px;
		li {
			display: inline-block;
			width: 140px;
			height: 40px;
			margin-right: 10px !important;
			margin-bottom: 10px;
			padding: 0;
			line-height: 40px;
			border-radius: 5px;
			background-color: #fff;
			color: #977c10;
			text-align: center;
			@include font-size(16);
			@include smooth-transition;
			&.selected, &:hover {
				background-color: #977c10;
				color: white;
				@include smooth-transition;
				a{
					color: white;
				}
			}
			a{
				display: inline-block;
				width: 100%;
			}
		}
	}
}

.mt35 {
	margin-top: 35px;
}

.list-movie-genres {
	padding: 45px 0 0;
	@media screen and (max-width: 768px) {
		padding: 55px 0 34px;
	}
}

div[id^="genre-"] {
	display: none;
	&.display {
		display: block;
	}
}