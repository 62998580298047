.page-news {
  &.index {
    #newsIndex {
      @include font-size(14);
      table {
        width: 100%;
        border-top: 1px solid #ccc;
        border-right: 1px solid #ccc;
        th {
          background: #eee url(/images/common/bg_gradation_gray.jpg) repeat-x;
          border: none;
          border-bottom: 1px solid #ccc;
          border-left: 1px solid #ccc;
          padding: 1em;
          vertical-align: top;
          text-align: center;
          font-weight: bold;
          border-right: none;
        }
        td {
          border-bottom: 1px solid #ccc;
          border-left: 1px dotted #ccc;
          background: #eee url(/images/common/bg_gradation_gray.jpg) repeat-x;
          padding: 1em;
          vertical-align: top;
          border-left: 1px dotted #ccc;
          .linkRed {
            color: #bc0d17;
            &:hover {
              color: #0087ba;
            }
          }
        }
      }

    }
  }
}
.page-newsdetail {
  @include font-size(13);
  #newsIndex table{
    border-top: 1px solid #ccc;
    border-right: 1px solid #ccc;
    clear:both;
    width:100%;
  }

  #newsIndex table th{
    background: #eee url(/images/common/bg_gradation_gray.jpg) repeat-x;
    border:none;
    border-bottom: 1px solid #ccc;
    border-left: 1px solid #ccc;
    padding:1em;
    vertical-align:top;
  }

  #newsIndex table td{
    border-bottom: 1px solid #ccc;
    border-left: 1px dotted #ccc;
    background: #eee url(/images/common/bg_gradation_gray.jpg) repeat-x;
    padding:1em;
    vertical-align:top;
  }
  #newsIndex table td a:link,
  #newsIndex table td a:visited {
    text-decoration: none;
  }

  #newsIndex .btnFot a:hover{
    background:url(/images/each/regular/btn_kikakuichiran.jpg) bottom;
  }
  .green_border{
    background:#eee url(/images/common/news_bgItem.jpg) no-repeat!important;
  }

  #newsIndex p{
    margin:10px;
    a {
      color: #000;
      text-decoration: underline;
      &:hover {
        color: #bc0d17;
      }
    }
  }

  #newsIndex .btnFot{
    margin:2em;
    text-align:center;
  }

  #backnumberFoot{
    border-top: 1px dotted #ccc;
    padding: 1.2em 0 0 0;
    margin: 1.5em 0;
    text-align:right;
  }

  #backFoot{
    border-top: 1px dotted #ccc;
    padding: 1.2em 0 0 0;
    margin: 1.5em 0;
    text-align:left;
  }
}
.sidebar-news {
  #lNav {
    position: relative;
    background: url(/images/common/lnav_bg.png) 0 55px repeat-y;
    margin: 0 0 15px 0;
    padding-bottom: 0;
    @media screen and (max-width: 768px){
      background: #36412e;
      padding-bottom: 15px;
    }
  }
  .lNavbottom {
    vertical-align: bottom;
    @media screen and (max-width: 768px){
      display: none;
    }
  }
}